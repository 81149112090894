@import "styles/variables";
@import "styles/colors";

.card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  user-select: none;
}

.card:last-child {
  margin-bottom: 0;
}

.image {
  //flex: 1;
  padding-right: 10px;
  padding-top: 3px;
}

.info {
  padding-left: 15px;
  color: $color-default-khaki;
  flex: 3
}

.title {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.71px;
  color: $color-default-khaki;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.arrow {
  font-size: 20px;
  cursor: pointer;
}

.author {
  font-size: 15px;
  letter-spacing: 0.88px;
  margin-bottom: 6px;
}

.duration {
  font-size: 15px;
  letter-spacing: 0.88px;
}

.quantity {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: $color-default-khaki
}

.number {
  font-size: 20px;
  min-width: 30px;
  text-align: center;
  font-weight: bolder;
}
