@import "styles/colors";

.instructions {
  font-size: 18px;
  text-align: center;
  color: $color-default-khaki;
  line-height: 1.4;
  margin-top: 180px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}