@import "styles/variables";
@import "styles/mixins";

$modal-width-mobile: 358px;

.modal {
  justify-content: flex-end;
  margin: 0 $spacing-2;
  padding-bottom: $spacing-2;
  width: auto;

  @include media("mobile-landscape") {
    width: $modal-width-mobile;
    margin: 0 auto;
  }

  @include media("tablet-landscape") {
    margin-left: auto;
    margin-right: $spacing-2;
    width: $video-sidebar-width-tablet - $spacing-2 * 2;
  }
}

.title {
  color: var(--color-fill-3);
}

.header {
  padding: $spacing-4 $spacing-4 0;
}

.cancel {
  width: 100%;
  text-align: center;
  color: var(--color-fill-2);
  background: var(--color-background-2);
  padding: $spacing-4;
  border-top: solid 1px var(--color-separator);
}

.cancelText {
  color: var(--color-fill-2);
}
