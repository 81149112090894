@import "styles/variables";
@import "styles/colors";

.wrapper {
  width: 100%;
  position: relative;
}

.back {
  position: absolute;
  color: $color-primary-action;
  cursor: pointer;
  font-size: 25px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 2px;
  color: $color-default-khaki;
  text-transform: uppercase;
  padding: 0 40px;

  &.gold {
    color: $color-primary-action;
  }
}
