@import "styles/variables";
@import "styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.labels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.label {
  margin: $spacing-2 0;
  color: var(--color-fill-1);
}

.spacingUntilErrorShown {
  margin-bottom: $spacing-8;
}

.fieldError {
  &,
  select {
    border-color: var(--color-semantics-warning);
  }
}

.caption {
  margin: $spacing-2 0 $spacing-3;
  color: var(--color-fill-2);
}

.action {
  cursor: pointer;
}
