@import "styles/variables";
@import "styles/helpers";

.modal {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.header {
  padding: $spacing-4 $spacing-6;
}

.content {
  padding: 0 $spacing-6;
  overflow: auto;
}

.entry {
  margin-bottom: $spacing-1;
}

.question {
  margin: 0;
  padding: $spacing-4 0;
}

.answer {
  margin: 0;
}

.icon {
  font-size: $spacing-6;
}

:global(.rc-collapse-item) {
  &:not(:global(.rc-collapse-item-active)) {
    .question {
      border-bottom: solid 1px var(--color-separator);
    }
  }
}
