@import "styles/variables";

.tabs {
  -webkit-tap-highlight-color: transparent;
}

.tabList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.tab {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  position: relative;
  height: $spacing-13;
  cursor: pointer;
  text-align: center;

  &:focus,
  &:hover {
    color: var(--color-primary-action-text);
    outline: 0;
  }
}

.tabSelected {
  &,
  > * {
    color: var(--color-primary-action-text);
  }
}

.tabDisabled {
  cursor: default;
}

.fullWidth {
  .tabList {
    display: flex;
    min-height: $spacing-12;
    height: $spacing-12;
  }

  .tab {
    flex: 1;
    height: 100%;
  }
}

.withIndicatorLine {
  .tab {
    color: var(--color-fill-1);
    &:focus,
    &:hover {
      color: currentColor;
    }
    &:after {
      z-index: 10;
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 1px;
      background-color: var(--color-background-2);
    }
  }
  .tabSelected {
    color: var(--color-fill-1);

    &:after {
      background-color: var(--color-primary-action-text);
    }
  }
}

.tabPanel {
  display: none;
}
.tabPanelSelected {
  display: block;
}
