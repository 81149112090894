@import "styles/variables";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :global {
    .rc-rate {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: $icon-size-s;
      height: $icon-size-s;
      display: inline-block;
      vertical-align: middle;
      font-weight: normal;
      font-style: normal;
      outline: none;
    }
    .rc-rate-rtl {
      direction: rtl;
    }
    .rc-rate-disabled .rc-rate-star {
      cursor: default;
      &:before,
      .rc-rate-content:before {
        cursor: default;
      }
      &:hover {
        transform: scale(1);
      }
    }
    .rc-rate-star {
      margin: 0;
      padding: 0;
      display: inline-block;
      margin-right: $spacing-1;
      position: relative;
      transition: all 0.3s;
      cursor: pointer;
      line-height: 1;
      outline: none;

      > div:focus {
        outline: none;
      }

      .rc-rate-rtl & {
        margin-right: 0;
        margin-left: 8px;
        float: right;
      }

      .rc-rate-star-first,
      .rc-rate-star-second {
        transition: all 0.3s;
        user-select: none;
      }

      &.rc-rate-star-focused,
      &:hover {
        transform: scale(1.1);
      }

      .rc-rate-star-first {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        overflow: hidden;
        opacity: 0;

        .rc-rate-rtl & {
          right: 0;
          left: auto;
        }
      }

      &.rc-rate-star-zero,
      &:not(.rc-rate-star-full) {
        svg path {
          fill: var(--color-background-2);
        }
      }
    }
  }
}
