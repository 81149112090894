.snipcart-layout__cols {
  height: 100%;
  display: flex;

  .snipcart-layout__col:first-of-type {
    width: 100%;
    padding: $spacing-6;
    background: var(--color-background-1);
    overflow: auto;

    @include media("tablet-landscape") {
      min-width: 460px;
      width: 460px;
      padding: $spacing-14;
    }
  }

  .snipcart-layout__col:last-of-type {
    padding: $spacing-8;
    width: 100%;

    @include media("desktop-small") {
      padding: $spacing-8 $spacing-30;
    }
    & > div {
      width: initial !important;
      position: relative !important;
    }
  }
}

.snipcart-form__set {
  border: none;
  margin: 0;
  padding: 0;

  .snipcart-checkbox,
  label[for="addressNotFound"] {
    display: none;
  }
}

.snipcart__box--badge {
  display: none;
}

.snipcart__font--subtitle {
  @extend %title1;
  @extend %large;
  color: var(--color-fill-1);
  font-weight: 500;
}

.snipcart-form__label {
  margin: $spacing-2 0;
  color: var(--color-fill-1);
  display: inline-block;

  &:not(label[for="address2"]) {
    &:after {
      content: "*";
    }
  }
}

.snipcart-input__input,
.snipcart-textbox--hidden {
  height: 40px;
  width: 100%;
  font-size: pxToRem(17);
  line-height: 1.176470588;
  font-weight: 500;
  padding: 10px;
  border: 1px solid var(--color-separator);
  color: var(--color-fill-1);
  background-color: var(--color-background-1);

  &::placeholder {
    color: var(--color-fill-3);
  }

  &.spacingUntilErrorShown {
    margin-bottom: $spacing-8;
  }

  &.inputError {
    border-color: var(--color-semantics-warning);
  }
}

.snipcart-form__select {
  @include media("tablet-portrait-max") {
    display: flex;
    align-items: center;
    color: var(--color-fill-1);
    line-height: 1.176470588;
    height: 40px;
    border: 1px solid var(--color-separator);
    width: 100%;
    padding: 10px;
    font-size: pxToRem(17);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: unset;
  }
}

%error-border {
  border-color: var(--color-semantics-warning);
}

.snipcart-input--invalid,
.snipcart-typeahead--invalid {
  .snipcart-input__input,
  .snipcart-textbox--hidden {
    @extend %error-border;
  }
}

select.snipcart-typeahead--invalid {
  @extend %error-border;
}

.snipcart-error-message {
  color: var(--color-semantics-warning);
  margin-top: $spacing-2;
}

.snipcart-flash-message--error {
  background-color: var(--color-separator);
}

.snipcart-flash-message {
  width: 100%;
  display: flex;
  align-items: center;
  padding: $spacing-4;
  margin: $spacing-2 0;

  svg {
    margin-right: $spacing-4;
  }

  .snipcart-flash-message__title {
    @extend %subtitle;
    @extend %large;
    font-weight: 500;
  }
  .snipcart-flash-message__description {
    display: none;
  }
}

.snipcart-typeahead__suggestions {
  list-style: none;
  padding: $spacing-4;
  margin: 0;
  border: 1px solid var(--color-separator);

  li {
    cursor: pointer;
    padding: $spacing-1 $spacing-2;
    &:hover {
      background: var(--color-background-2);
    }
  }

  li:not(:last-of-type) {
    margin-bottom: $spacing-1;
  }
}

.snipcart-form__field {
  margin-bottom: $spacing-2;
}

.snipcart-form__footer {
  display: flex;
  justify-content: flex-end;
}

.snipcart-checkout-step__cols {
  display: flex;
  flex-direction: column;
  margin: $spacing-4 0;

  .snipcart-checkout-step__col {
    display: flex;

    &:first-of-type {
      margin-bottom: $spacing-4;
    }
    .snipcart-checkout-step__icon {
      display: none;
    }

    .snipcart-billing-completed__step-title {
      @extend %subtitle;
      font-weight: 500;
      color: var(--color-fill-3);
    }

    .snipcart-billing-completed__information {
      @extend %subtitle;
      margin-top: $spacing-1;
      display: block;
    }
  }
}

.snipcart-billing-completed__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .snipcart__box--title {
    .snipcart-billing-completed__title {
      @extend %title3;
      color: var(--color-fill-3);
    }
  }

  .snipcart__actions--link {
    @extend %subtitle;
    font-weight: 500;
    color: var(--color-primary-action-text);
    text-decoration: none;
  }
}

#snipcart-checkout-step-billing {
  margin-bottom: $spacing-14;
}

.snipcart-checkout-step {
  & + #snipcart-checkout-step-payment {
    .snipcart__box--gray {
      display: none;
    }
  }
}

.address-checkbox {
  .snipcart-checkbox {
    margin-right: $spacing-2;
    appearance: checkbox;
  }
}

#snipcart-billing-form {
  .snipcart-cart-button {
    width: 100%;
  }
}

select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

.snipcart-form__address__autocomplete {
  & > .snipcart-form__select {
    display: none;
  }
}

.snipcart-cart--opened {
  body {
    overflow: hidden;
  }
}

.snipcart-input {
  input:disabled {
    color: var(--color-fill-2);
    background-color: var(--color-background-2);
  }
}

.snipcart-payment-methods-list-item {
  margin-bottom: $spacing-4;
}
