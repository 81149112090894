@import "styles/mixins";

.container {
  background-color: var(--color-background-1);
  box-shadow: 0px 0px 16px rgba(84, 104, 92, 0.2);
  text-align: center;
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  @include media("tablet-landscape") {
    position: relative;
  }
}
