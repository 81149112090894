@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.input {
  padding: 8px;
  font-size: 17px;
  border: solid 2px rgba(84, 104, 92, 0.15);
  width: 100%;
  color: $color-default-khaki;
}

.label {
  margin-bottom: 3px;
  display: flex;
  font-size: 17px;
  font-weight: 500;
  color: #54685d;
}

.formControl {
  position: relative;
  width: 100%;
}

.secondLabel {
  width: 24px;
  height: 24px;
  fill: $color-default-khaki;
}

.passwordIcon {
  position: absolute;
  cursor: pointer;
  right: 8px;
  bottom: 10px;
  width: 22px;
  height: 22px;
  fill: $color-default-khaki;
}
