@import "styles/variables";
@import "styles/mixins";

//Light banner
$color-background-transparent_no_auth: rgba(255, 255, 255, 0.75);
$color-background-solid_no_auth: #fff;
$color-text_no_auth: #445571;
$color-input-border_no_auth: rgba(68, 85, 113, 0.45);
$color-text-subscribe_no_auth: rgba(68, 85, 113, 0.5);
//DarkBanner
$color-background-transparent_auth: rgba(47, 60, 52, 0.75);
$color-background-solid_auth: #2f3c34;
$color-text_auth: #fff;
$color-input-border_auth: rgba(255, 255, 255, 0.75);
$color-text-subscribe_auth: rgba(255, 255, 255, 0.5);

$color-close-button: rgba(84, 104, 93, 0.75);

.wrapper {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  display: flex;
  background-color: $color-background-solid_auth;
  align-items: center;
  bottom: 0;
  flex-direction: column;
  padding: $spacing-4 $spacing-6;
  transition: all 0.2s linear;

  @include media("tablet-landscape") {
    background-color: $color-background-transparent_auth;
    padding: $spacing-4 $spacing-5;
    top: 101px;
    bottom: auto;
    flex-direction: row;
    justify-content: space-between;

  }

  &.noAuth {
    background-color: $color-background-solid_no_auth;
    @include media("tablet-landscape") {
      background-color: $color-background-transparent_no_auth
    }
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 2;
  width: 100%;
  max-width: 400px;
  margin-bottom: 16px;
  @include media("tablet-landscape") {
    margin-bottom: 0;
  }
}

.closeMobile {
  color: $color-text_auth;
  display: block;
  @include media("tablet-landscape") {
    display: none;
  }

  &.noAuth {
    color: $color-text_no_auth;
  }
}

.title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 2px;
  color: $color-text_auth;
  text-transform: uppercase;
  @include media("tablet-landscape") {
    font-size: 20px;
  }

  &.noAuth {
    color: $color-text_no_auth;
  }
}

.controls {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  @include media("tablet-landscape") {
    max-width: 600px;
    flex: 3;
    flex-direction: row;
  }
}

.input {
  display: block;
  border: solid 2px $color-input-border_auth;
  padding: 8px 12px;
  font-size: 17px;
  font-weight: 500;
  max-height: 40px;
  color: $color-text_auth;
  width: 100%;
  margin-bottom: 16px;
  @include media("tablet-landscape") {
    margin-bottom: 0;
  }

  &::placeholder {
    color: $color-input-border_auth;
  }

  &.noAuth {
    color: $color-text_no_auth;
    border-color: $color-input-border_no_auth;

    &::placeholder {
      color: $color-input-border_no_auth;;
    }
  }

  &.error {
    border-color: #ff6d2d;
  }
}


.inputWrapper {
  width: 100%;
  position: relative;
  @include media("tablet-landscape") {
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.errorMessage {
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: #ff6d2d;
  bottom: 0;
  @include media("tablet-landscape") {
    bottom: -14px;

  }
}

.submit {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  color: $color-text_auth;
  text-transform: uppercase;
  margin-bottom: 16px;
  @include media("tablet-landscape") {
    margin-bottom: 0;
  }

  &.noAuth {
    color: $color-text_no_auth;
  }
}

.terms {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;

}

.text {
  font-size: 12px;
  line-height: 1.33;
  color: $color-text-subscribe_auth;

  &.noAuth {
    color: $color-text-subscribe_no_auth;
  }
}

.closeDesktop {
  color: $color-text-subscribe_auth;
  display: none;
  cursor: pointer;
  @include media("tablet-landscape") {
    display: block;
    margin-left: 20px;
  }

  &.noAuth {
    color: $color-text-subscribe_no_auth
  }
}

.link {
  text-decoration: underline;
  color: inherit;
}
