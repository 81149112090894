@import "styles/variables";
@import "styles/mixins";

.bottomLink {
  display: inline-block;
  width: 50%;
  margin-bottom: $spacing-8;
  margin-inline-end: 0;

  @include media("tablet-landscape") {
    width: initial;
    margin-bottom: 0;

    &:not(:last-of-type) {
      margin-inline-end: $spacing-8;
    }
  }
}

.label {
  color: var(--color-fill-1);
}

.link {
  text-decoration: none;
}
