@import "styles/variables";
@import "styles/mixins";

$no-results-circles-width: 488px;
$no-results-wrapper-height: 500px;

.wrapper {
  text-align: center;
  height: $no-results-wrapper-height;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $spacing-10;

  .image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @include media("tablet-landscape") {
    margin-bottom: $spacing-15;
  }
}
