@import "styles/variables";
@import "styles/mixins";

$sessionCard-image-height-mobile: 40px;
$sessionCard-image-height-tablet: 188px;
$sessionCard-image-height-desktop: 220px;
$sessionCard-topWidget-CTA-height: $spacing-9;
$sessionCard-hover-box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);

%textTruncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sessionCard {
  margin-bottom: $spacing-6;
  background: var(--color-background-2);
  text-align: left;

  &:hover {
    box-shadow: $sessionCard-hover-box-shadow;
  }

  @include media("tablet-landscape") {
    &.isHome {
      margin-bottom: $spacing-6;
    }
  }

  .imageWrapper {
    height: $sessionCard-image-height-tablet;
    position: relative;
    overflow: visible;

    @include media("tablet-landscape") {
      height: $sessionCard-image-height-desktop;
    }

    .image {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    .topWidget {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      padding: $spacing-4;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;

      .sessionCTA {
        height: $sessionCard-topWidget-CTA-height;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 $spacing-4;
        border-radius: $spacing-6;
        background: var(--color-separator);
        backdrop-filter: blur($spacing-12);

        &.liveSession {
          background: var(--live-session-gradient);
          backdrop-filter: none;
        }

        &.bookedSession {
          background: var(--color-primary-action);
        }

        &.cancelledSession {
          background: var(--color-semantics-warning);
        }
      }
    }
  }

  .title {
    color: var(--color-fill-3);
    @extend %textTruncate;
  }

  .subtitle {
    margin-bottom: $spacing-1;
    color: var(--color-fill-1);
    @extend %textTruncate;
    width: 100%;
  }

  .caption {
    color: var(--color-fill-2);
    @extend %textTruncate;
    padding-bottom: 0.15rem;
  }

  .cardFooter{
    display: flex;
    justify-content: space-between;
  }

  .textWrapper {
    width: 100%;
    padding: $spacing-4;
    position: relative;
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .caption {
      flex-shrink: 0;
    }
  }

  &.smallSessionCard {
    display: flex;
    flex-direction: row;
    background: transparent;

    &:hover {
      box-shadow: none;
    }

    @include media("tablet-portrait") {
      flex-direction: column;
      background: var(--color-background-2);

      &:hover {
        box-shadow: $sessionCard-hover-box-shadow;
      }
    }

    .imageWrapper {
      height: $sessionCard-image-height-mobile;
      max-width: $spacing-16;
      margin-right: $spacing-4;
      margin-top: $spacing-2;
      flex-shrink: 0;

      @include media("tablet-portrait") {
        max-width: 100%;
        height: $sessionCard-image-height-desktop;
        margin-top: 0;
      }
    }

    .textWrapper {
      padding: 0;
      width: calc(100% - 80px);

      @include media("tablet-portrait") {
        padding: $spacing-4;
        width: 100%;
      }
    }

    .topWidget {
      display: none;
      @include media("tablet-portrait") {
        display: flex;
      }
    }

    .bookedIcon {
      position: absolute;
      right: -$spacing-2;
      top: -$spacing-2;
      background: var(--color-primary-action);
      font-size: pxToRem(16);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      z-index: 2;

      @include media("tablet-portrait") {
        display: none;
      }
    }
  }

  .bookedIcon {
    display: none;
  }

  .liveSessionText {
    display: flex;
    align-items: center;
    @extend .link;
  }

  .sessionTime {
    //text-shadow: 0px 1px 8px rgba(0, 0, 0, 0.4);

    @include media("tablet-landscape") {
      letter-spacing: 0.05em; // Text Breaks for 1024-1150
    }

    @include media("desktop-small") {
      letter-spacing: 0.1em; // Revert
    }
  }
}

.link {
  text-decoration: none;
  color: inherit;
}

.timeMobile {
  margin-top: $spacing-2;

  @include media("tablet-portrait") {
    display: none;
  }
}

.optionsToggle {
  position: absolute;
  bottom: $spacing-4;
  right: $spacing-2;
  font-size: $spacing-6;
}

.cancelledMobile {
  margin-top: $spacing-1;
  color: var(--color-semantics-warning);

  @include media("tablet-portrait") {
    display: none;
  }
}
