@import "styles/variables";
@import "styles/mixins";

.endCallButton {
  background-color: var(--color-semantics-warning);
  width: $input-height;
  height: $input-height;
  border-radius: $input-height / 2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

  @include media("tablet-landscape") {
    width: auto;
    padding: 0 #{$spacing-4};
  }
}
