@import "styles/variables";
@import "styles/mixins";

$topic-image-size-mobile: 328px;
$topic-image-size-tablet: 460px;
$topic-image-size-desktop: 496px;

.title {
  margin-bottom: $spacing-6;

  @include media("tablet-landscape") {
    margin-bottom: $spacing-9;
  }
}

.paragraph {
  margin-bottom: $spacing-6;

  @include media("tablet-landscape") {
    margin-bottom: $spacing-9;
  }
}

.imageWrapper {
  position: relative;
  height: $topic-image-size-mobile;
  max-width: 100%;
  margin: auto;
  margin-bottom: $spacing-8;

  @include media("tablet-portrait") {
    width: $topic-image-size-tablet;
    height: $topic-image-size-tablet;
    margin: initial;
  }

  @include media("desktop-small") {
    width: $topic-image-size-desktop;
    height: $topic-image-size-desktop;
  }

  .topicImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
  }

  .blurredImage {
    position: absolute;
    top: $spacing-4;
    left: 0;
    right: 0;
    margin: auto;
    width: calc(100% - #{$spacing-8});
    height: 100%;
    opacity: 0.4;
    filter: blur(22px);
    z-index: 0;
  }
}

.reverseRow {
  flex-direction: row-reverse;

  .imageWrapper {
    @include media("tablet-portrait") {
      margin-left: auto;
    }
  }
}
