@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.contact {
  margin-top: auto;
  text-align: center;
  font-size: 15px;
  line-height: 1.27;
  color: $color-khaki-light;
  margin-bottom: 20px;
  @include media("tablet-portrait") {
    margin-bottom: 0;

  }
}

.link {
  color: $color-primary-action;
}