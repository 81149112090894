@import "styles/mixins";
@import "styles/variables";

$max-banner-height: 232px;
$max-banner-height-tablet: 264px;
$max-banner-height-desktop: 208px;

.container {
  padding: $spacing-12 0;

  @include media("tablet-landscape") {
    padding: $spacing-15 0;
  }
}

.imageWrapper {
  padding-bottom: 0;
  height: $max-banner-height;

  @include media("tablet-landscape") {
    height: $max-banner-height-tablet;
  }

  @include media("desktop-medium") {
    height: $max-banner-height-desktop;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.entry {
  margin-bottom: $spacing-2;
}

.sectionTitle {
  margin-bottom: $spacing-6;

  @include media("desktop-medium") {
    margin-bottom: $spacing-9;
  }
}

.section {
  margin-bottom: $spacing-18;
}

.sectionLink {
  margin-bottom: $spacing-4;
  cursor: pointer;
}

.question {
  margin: 0;
  padding: $spacing-8 0;
}

.answer {
  margin: 0;
}

.icon {
  font-size: $spacing-6;
}

:global(.rc-collapse-item) {
  &:not(:global(.rc-collapse-item-active)) {
    .question {
      border-bottom: solid 1px var(--color-separator);
    }
  }
}
