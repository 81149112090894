@import "styles/variables";
@import "styles/mixins";

%third {
  background: linear-gradient(
                  to bottom,
                  var(--color-background-1-reverse) 40%,
                  var(--color-background-1) 40%,
                  var(--color-background-1) 60%
  );
}

%quarter {
  background: linear-gradient(
                  to bottom,
                  var(--color-background-1-reverse) 20%,
                  var(--color-background-1) 20%,
                  var(--color-background-1) 80%
  );
}

%half {
  background: linear-gradient(
                  to bottom,
                  var(--color-background-1-reverse) 50%,
                  var(--color-background-1) 50%
  );
}

.container {
  color: var(--color-fill-1);
  background-color: var(--color-background-1);
  p {
    //margin-bottom: $spacing-4;
  }
  h1,
  h1,
  h3 {
    //margin: $spacing-6 0;
  }
  a {
    text-decoration: none;
    color: var(--color-link);
    transition: .3s color ;
    &:hover{
      color: var(--color-link-hover);
    }
  }

  &:global(.khaki-theme) + :global(.white-theme) {
    &:not(.noBackgroundOverlap) {
      color: var(--color-fill-1-reverse);

      &.quarter {
        @extend %quarter;
      }

      &.half {
        @extend %half;
      }

      &.third {
        @extend %third;
      }
    }
  }

  &:global(.white-theme) + :global(.khaki-theme) {
    &:not(.noBackgroundOverlap) {
      color: var(--color-fill-1-reverse);

      &.quarter {
        @extend %quarter;
      }

      &.half {
        @extend %half;
      }

      &.third {
        @extend %third;
      }
    }
  }

  &:global(.text-align-left){text-align: left}
  &:global(.text-align-right){text-align: right}
  &:global(.text-align-center){text-align: center}
}


.set-font-size {
  font-size: 1.1925rem;
}
