@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.input {
  padding: 8px;
  font-size: 17px;
  border: solid 2px rgba(84, 104, 92, 0.15);
  width: 100%;
  color: $color-default-khaki;
}