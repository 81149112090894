@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.swiper-pagination-bullet-active {
  background-color: #54685d
}

.swiper-pagination-bullet {
  background-color: rgba(84, 104, 93, 0.5)
}

.swiper-next-el {
  background-color: #2f3c34;
}


.swiper-button-prev {
  display: none;
  @include media("tablet-portrait") {
    display: block;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: rgba(64, 79, 71, 0.7) url("/ic-back.svg") no-repeat center center;
  }
}

.swiper-button-prev:after {
  display: none;
}

.swiper-button-next {
  display: none;
  @include media("tablet-portrait") {
    display: block;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: rgba(64, 79, 71, 0.7) url("/ic-next.svg") no-repeat center center;

  }
}

.swiper-button-next::after {
  display: none;
}

.pagination-bannerbullets {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  z-index: 30;
  justify-content: center;
}

.pagination-bullet {
  border-radius: 50%;
  margin: 2px;
  display: block;
  background-color: rgba(155, 166, 161, 0.5);
  width: 8px;
  height: 8px;
  cursor: pointer;

}

.pagination-active {
  background-color: #e0b574;
}