@import 'styles/colors';
$color1: #fff;
$color2: $color-khaki-light;

.radioItem{
  display: flex;
  align-items: center;
  border: 2px solid lightgray;
  padding: 10px 20px;
  max-width: 250px;
  margin-bottom: 12px;
  border-radius: 8px;
}
.cardProvider{
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardImage{
  max-width: 50px;
  max-height: 50px;
}

.label{
  cursor: pointer;
  font-size: 17px;
  margin-left: 10px;
  padding-bottom: 2px;
  color: $color-default-khaki;
}
.input[type="radio"] {
  appearance: none;
  width: 19px;
  height: 19px;
  border: 1px solid $color-khaki-light;
  border-radius: 50%;
  background-clip: content-box;
  padding: 4px;
}

.input[type="radio"]:checked {
  background-color: $color-khaki-light;
}
.cardInfo {
  margin-left: auto;
  text-align: right;
  color: $color-default-khaki;
}
.cardNumber {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
}

.expiresAt {
  font-size: 12px;
}