@import "styles/variables";
@import "styles/mixins";

.container {
  width: 100%;
  flex-direction: column;
  position: relative;
  min-height: 300px;
  max-height: 800px;
  overflow: hidden;
  @include media("tablet-landscape") {
    width: 80%;
    max-height: none;
  }

  &.expanded {
    max-height: none;
  }
}


.tabContent {
  @include media("tablet-landscape") {
    overflow-y: auto;
    max-height: 680px;
  }
}

.tabBlock {
  display: flex;
  justify-content: flex-start;
  @include media("tablet-landscape") {
    justify-content: flex-start;
  }
}

.tab {
  padding: 15px 30px;
  text-align: center;
  background: #5c6f65;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
  border: none;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;

}

.tab:not(:last-child) {
  margin-right: 5px;
  @include media("tablet-landscape") {
    margin-right: 20px;
  }
}

.activeTab {
  background: #6d7e75;
}

.content {
  padding: 15px;
  width: 100%;
  height: 100%;
  display: none;
  background-color: #6d7e75;
  @include media("tablet-landscape") {
    padding: 30px;
  }
}

.activeContent {
  display: block;
}

.icon {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  color: #d9b677;
}

.expandButton {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  color: #d9b677;
  z-index: 2;
  @include media("tablet-landscape") {
    display: none;
  }
}

.limiter {
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, #54685d 0%, rgba(109, 126, 117, 0.75) 90%);
  width: 100%;
  height: 60px;
  opacity: 1;
  transition: 0.3s;
  z-index: 1;
  @include media("tablet-landscape") {
    display: none;
  }
}