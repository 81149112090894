@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.container {
  background-color: #fff;
  margin: 0 10px;
  padding: 20px;
  color: #54685d;
  @include media("tablet-landscape") {
    padding: 40px;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.left {
  display: flex;
  align-items: center;
}

.class, .date {
  font-size: 15px;
}

.date {
  display: none;
  @include media("tablet-landscape") {
    display: block;
  }
}

.icon {
  color: $color-primary-action;
  margin-right: 24px;
  font-size: 40px;
}

.review {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-size: 17px;
  line-height: 1.65;
  overflow: hidden;
  max-height: 110px;
  margin-bottom: 20px;

}

.more {
  display: block;
  text-align: right;
  font-size: 17px;
  line-height: 1.65;
  color: #d9b677;
  cursor: pointer;
}

.bottom {
  display: flex;
  align-items: center;
}

.image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;

}

.name {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.65;
}