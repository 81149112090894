@import "styles/variables";
@import "styles/mixins";

$video-ratio: 0.6;

.videoChat {
  position: relative;
  width: 200px;
  height: 200px * $video-ratio;

  @include media("desktop-small") {
    width: 12.5vw;
    height: calc(12.5vw * #{$video-ratio});
  }

  @include media("desktop-medium") {
    width: 200px;
    height: 200px * $video-ratio;
  }

  &:not(:last-child) {
    margin-right: 2px;
  }

  &.fixedAspectRatio {
    width: 100%;
    height: auto;
    padding-top: $image-aspect-ratio-5-to-3;

    @include media("tablet-portrait-max", "landscape") {
      height: 100vh;
      height: -webkit-fill-available;
      height: -moz-available;
      padding-top: 0;
    }

    :global(.OT_publisher),
    :global(.OT_subscriber) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  :global(.OT_widget-container) {
    background-color: var(--color-background-2);
  }

  :global(.OT_video-poster) {
    display: none !important;
  }

  &.isHidden,
  video {
    display: none;
  }
}
