@import "styles/variables";
@import "styles/mixins";

.container {
  padding: 0 $spacing-6;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @include media("tablet-landscape") {
    padding: 0 $auth-layout-content-spacing-tablet;
  }
}
.container::-webkit-scrollbar {
  display: none;
}

.header {
  padding-top: $auth-layout-content-spacing-mobile;
  margin-bottom: $spacing-7;

  @include media("tablet-landscape") {
    padding-top: $auth-layout-content-spacing-tablet;
  }
}

%bottom-spacing {
  padding-bottom: $spacing-6;

  @include media("tablet-landscape") {
    padding-bottom: $auth-layout-content-spacing-tablet;
  }
}

.content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:last-child {
    @extend %bottom-spacing;
  }
}

.footer {
  margin-top: $spacing-7;
  @extend %bottom-spacing;
}
