@import "styles/variables";

.title {
  margin-bottom: $spacing-2;
}

.subtitle {
  margin-bottom: $spacing-6;
}

.itemWrapper {
  display: flex;
  justify-content: center;
}

.itemContainer {
  margin-right: $spacing-4;
}
.item {
  margin-bottom: 0;
}
