@import "styles/variables";
@import "styles/mixins";

$max-banner-height: 540px;

.wrapper {
  display: flex;
  overflow: hidden;
}

.content {
  padding-top: 40%;
  min-height: 300px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: 50% 50%;

  .subtitle {
    text-align: center;
    position: absolute;
    top: 70%;
    z-index: 2;
    left: 0;
    right: 0;
  }

  @include media("desktop-medium") {
    padding-top: 0;
    height: $max-banner-height;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.video {
  position: absolute;
  top: 30%;
  mask-image: url(../../../assets/images/mask-logo-new.svg);
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top center;
  object-fit: fill;
  z-index: 1;
  left: calc(50%);
  transform: translateX(-50%);
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 500ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  width: 600px;

  @include media("tablet-landscape") {
    width: 70%;
    max-width: 1200px;
    top: 35%;
  }
}
