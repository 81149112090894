@import "styles/variables";

.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  &.withHeader {
    padding-top: $navigation-height-default;
  }
}

.header {
  background: var(--color-background-1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.headerWrapper {
  height: $navigation-height-default;
}
