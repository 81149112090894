@import "styles/variables";
@import "styles/mixins";

.commentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: $spacing-4 0;

  .user {
    display: flex;
    align-items: center;
  }

  .avatar {
    margin-right: $spacing-2;
  }

  .comment {
    margin-top: $spacing-3;
    margin-left: $spacing-8;
    flex: 1 1 100%;
  }
}

.delete {
  width: 100%;
  text-align: center;
  color: var(--color-primary-action-text);
  padding: $spacing-4;
  border-top: solid 1px var(--color-background-2);
}

.deleteText {
  color: var(--color-primary-action-text);
}
