@import "styles/variables";
@import "styles/mixins";

.sidebar {
  &,
  .overflow {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: -webkit-fill-available;
    height: -moz-available;
    height: 100%;
  }
  .overflow {
    &.hideChat {
      height: 0;
    }
  }
}
