@import "styles/variables";
@import "styles/mixins";

.image {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    border: 1px solid var(--color-separator);
  }
  .avatar {
    width: 100%;
    height: 100%;
  }
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: currentColor;
  object-fit: cover;

  &.avatarWithShadow {
    box-shadow: 0px 0px 24px -8px currentColor;
  }

  &.avatarColor0 {
    color: var(--color-background-2);

    .avatarIcon {
      color: var(--color-primary-action-text);
    }
  }
  &.avatarColor1 {
    color: var(--color-violet-yang);

    .avatarIcon {
      color: var(--color-green-base);
    }
  }
  &.avatarColor2 {
    color: var(--color-orange-base);

    .avatarIcon {
      color: var(--color-pink-yang);
    }
  }
  &.avatarColor3 {
    color: var(--color-orange-yang);

    .avatarIcon {
      color: var(--color-red-base);
    }
  }
  &.avatarColor4 {
    color: var(--color-green-yin);

    .avatarIcon {
      color: var(--color-orange-yang);
    }
  }
  &.avatarColor5 {
    color: var(--color-yellow-yang);

    .avatarIcon {
      color: var(--color-violet-yin);
    }
  }
  &.avatarColor6 {
    color: var(--color-yellow-base);

    .avatarIcon {
      color: var(--color-blue-yin);
    }
  }
}

.default {
  width: $spacing-9;
  height: $spacing-9;
  font-size: $spacing-6;
}
.large {
  width: $spacing-12;
  height: $spacing-12;
  font-size: $spacing-8;
}
.medium {
  width: $spacing-8;
  height: $spacing-8;
  font-size: $spacing-5;
}
.small {
  width: $spacing-6;
  height: $spacing-6;
  font-size: $spacing-4;
}
