@import "styles/variables";
@import "styles/mixins";

$image-width-mobile: 120px;
$image-width-tablet: 183px;
$image-width-desktop: 240px;

.uspWrapper {
  &.alignCenter {
    text-align: center;
  }

  &.alignRight {
    text-align: right;
  }

  .heading {
    color: var(--color-fill-1);
    margin-bottom: $spacing-16;

    @include media("tablet-landscape") {
      margin-bottom: $spacing-20;
    }
    @include media("desktop-small") {
      margin-bottom: $spacing-24;
    }
  }

  .uspItem {
    &:not(:last-of-type) {
      margin-bottom: $spacing-16;

      @include media("tablet-landscape") {
        margin-bottom: 0;
      }
    }

    .image {
      max-width: $image-width-mobile;
      width: 100%;
      display: block;
      margin: auto;
      margin-bottom: $spacing-9;

      @include media("tablet-landscape") {
        max-width: $image-width-tablet;
      }

      @include media("desktop-small") {
        max-width: $image-width-desktop;
      }
    }

    .title {
      color: var(--color-fill-1);
    }

    .description {
      color: var(--color-fill-2);
    }

    .hasLink {
      text-decoration: none;
    }

    .top_bump {
      margin-top: 1.7em;
      margin-bottom: 1em;
    }
  }
}
