@import "styles/variables";
@import "styles/mixins";

.wrapper {
  text-align: center;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: $spacing-10;

  .image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: pxToRem(332);

    @include media("tablet-landscape") {
      font-size: pxToRem(490);
    }
  }
}

.button {
  margin-top: $spacing-4;

  @include media("tablet-landscape") {
    margin-top: $spacing-6;
  }
}
