@import "styles/variables";

.header {
  display: flex;
  align-items: center;

  .clearButton {
    margin-left: $spacing-4;
  }
}

.content {
  overflow: auto;
  padding: $spacing-10 0;
}
