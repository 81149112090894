@import "styles/colors";

.heading {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: $color-default-khaki;
  text-transform: uppercase;
  align-self: center;
  width: 94%;
}
