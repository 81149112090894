@import "styles/variables";
@import "styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #6d7a75;
  padding: 24px 24px;

  @include media("tablet-landscape") {
    padding: 16px 24px;
    flex-direction: row;
  }
}

.labels {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 20px;
  @include media("tablet-landscape") {
    flex-direction: row;
    align-items: center;
  }
}

.label {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  @include media("tablet-landscape") {
    margin-bottom: 0;
 }
 .question {
    cursor: pointer;
    margin-left: 5px;
    font-size: 12px;
  }
}
.length{
  margin-right: 5px;
}

.intensity {
  cursor: pointer;
  text-transform: capitalize;
}

.icon {
  margin-right: 16px;
}

.price {
  font-size: 20px;
  font-weight: 500;
  margin-right: 20px;
  @include media("tablet-landscape") {
    font-size: 28px;
    margin-bottom: 0;

  }
}

.button {
}

.modal {
  width: auto;
  @include media("tablet-landscape") {
    width: 600px;
    max-height: 480px;
  }
}

.modalContent {
  padding: 20px;
  @include media("tablet-landscape") {
    padding: 40px;
  }
}

.modalSubtitle {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #d9b677;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.modalText {
  margin-bottom: 20px;
}
