@import "styles/variables";
@import "styles/mixins";

.sessions {
  text-align: left;
  margin-bottom: $spacing-6;
}

.row {
  border-bottom: solid 1px var(--color-separator);

  > div {
    padding-top: $spacing-4;
    padding-bottom: $spacing-4;
    display: flex;
    align-items: center;
    &:first-child {
      padding-left: 0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    }
  }
  .action {
    justify-content: flex-end;
  }
  .image {
    width: 56px;
    height: 56px;
    margin-right: $spacing-6;
  }
}

.titleContainer {
  display: flex;
}

.icon {
  font-size: $spacing-5;
  color: var(--color-primary-action-text);

  &:first-child {
    margin-right: $spacing-5;
  }
}

.sessionCardMobile {
  border-bottom: solid 1px var(--color-separator);
  padding: $spacing-6 0 $spacing-4;
  text-align: left;
  position: relative;

  &:first-child {
    border-top: solid 1px var(--color-separator);
  }
  .col2 {
    text-align: right;
  }
  .sessionName {
    margin-bottom: $spacing-1;
  }
  .actions {
    display: flex;
    position: absolute;
    right: 0;
    bottom: $spacing-4;
  }
  .date {
    color: var(--color-fill-3);
  }
}

.title {
  color: var(--color-fill-3);
}

.cancelled {
  margin-top: $spacing-1;
  color: var(--color-semantics-warning);

  @include media("tablet-portrait") {
    margin-top: 0;
  }
}
