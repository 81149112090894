@import "styles/colors";

.wrapper {
  background-image: url("../../../../assets/images/clouds.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

