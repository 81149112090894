@import "styles/variables";
@import "styles/mixins";

.circularButton {
  width: $input-height;
  height: $input-height;
  background-color: var(--color-background-1);
  border: 1px solid var(--color-fill-3);
  color: var(--color-fill-1);
  border-radius: 50%;
  font-size: pxToRem(24);
  transition: 0.4s background-color;
}

.isFloating {
  background-color: var(--color-separator);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(48px);
  border: none;
}

.borderless {
  border: none;
}
