@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.wrapper {
  background-color: #eff0f0;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  justify-content: space-around;
  @include media("tablet-landscape") {
    min-height: 100%;
    flex-direction: row;
    padding: 40px 70px;
  }

}


.left {
  width: 100%;
  @include media("tablet-landscape") {
    width: 36%;
    display: flex;
    flex-direction: column;
  }
}

.right {
  background-color: #fff;
  padding: 24px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @include media("tablet-landscape") {
    padding: 24px 30px;
    width: 42%;
  }
}