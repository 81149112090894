@import "styles/variables";

.subtitle {
  margin-bottom: $spacing-2;
}
.title {
  color: currentColor;
}

.button {
  margin-top: $spacing-6;
}

.experiencesSwiper {
  padding: $spacing-12 0;
}
