@import "styles/variables";
@import "styles/mixins";

.wrapper {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  @include media("tablet-landscape") {
    flex-direction: row;
  }
}