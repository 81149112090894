@import "styles/variables";
@import "styles/mixins";

.link {
  color: var(--color-primary-action-text);
}

.practitionerSubheader {
  margin-bottom: $spacing-2;
  display: none;

  @include media("tablet-landscape") {
    display: block;
  }
}

.practitioner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: $spacing-8;

  @include media("tablet-landscape") {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0;
    text-align: initial;
  }

  .avatar {
    flex-shrink: 0;
    margin-bottom: $spacing-4;

    @include media("tablet-landscape") {
      margin-bottom: 0;
    }
  }

  .practitionerText {
    margin-left: $spacing-4;

    .practitionerDescription {
      color: var(--color-fill-2);
    }
  }
}
