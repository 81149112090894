@import "styles/mixins";

.backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;

  z-index: 30;
}

.wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  height: 100%;
  background-color: #fff;
  width: 100%;
  @include media("tablet-portrait") {
    max-width: 700px;
  }
}