@import "styles/variables";
@import "styles/mixins";

.experiences{
  display: flex;
  flex-wrap: wrap;
}
.experience{
  background-color: #de9073;
  padding: 5px 10px;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 18px;
  color: #fff;
  margin-bottom: 8px;
  cursor: pointer;

  @include media("tablet-landscape") {
  margin-bottom: 0;
  }
}
