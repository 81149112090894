@import "styles/variables";
@import "styles/mixins";

$tablet-width: 342px;

.modal {
  width: 95%;
  max-width: $tablet-width;

  .content {
    padding: $spacing-4 $spacing-6;
    max-height: 435px;
    overflow: auto;
  }

  @include media("tablet-landscape") {
    width: $tablet-width;
  }
}

.attendeesList {
  padding: $spacing-3 0;
  overflow: auto;

  .attendee {
    padding: $spacing-3 0;
    display: flex;
    align-items: center;
  }
  .avatar {
    margin-right: $spacing-2;
  }
}
