@import "styles/variables";
@import "styles/mixins";

.header {
  text-align: center;
  background-image: url("../../../../assets/images/wisdomBg.jpg");
  background-clip: text;
  background-size: cover;
  background-position: center 45%;
  color: transparent;
  padding: $spacing-10 0;

  @include media("tablet-landscape") {
    padding: $spacing-15 0;
  }
}

.articleHeader {
  text-align: center;
}

.articleMeta {
  font-size: 1.1925rem;
  margin: $spacing-3 0;
  .meta {
    color: var(--color-fill-3);
  }
}

.section {
  margin-bottom: 15px;
}

.topShareBtnContainer {
  text-align: center;
}

.horizontalLine {
  padding-top: 2rem;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #54685c;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.horizontalLineText {
  background:#fff;
  padding:0 10px;
}