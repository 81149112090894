@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-default-khaki;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid lightgrey;
  letter-spacing: 2px;
  padding: 25px 20px;
  @include media("tablet-portrait") {
    padding: 25px 50px;

  }
}

.controls {
  display: flex;
  align-items: center;
  cursor: pointer;
}


.label {
  width: 20px;
  height: 20px;
  color: $color-default-khaki;
  margin-right: 10px;
}