@import "styles/variables";
@import "styles/mixins";

.input {
  height: $input-height;
  width: 100%;
  font-size: pxToRem(17);
  line-height: 1.176470588;
  font-weight: 500;
  padding: 10px;
  border: 1px solid var(--color-separator);
  color: var(--color-fill-1);
  background-color: var(--color-background-1);

  &:focus {
    border-color: var(--color-primary-action-text);
  }

  &::placeholder {
    color: var(--color-fill-3);
  }
}

.label {
  display: block;
  margin: $spacing-2 0;
  color: var(--color-fill-1);
}
