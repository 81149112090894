@import "styles/variables";
@import "styles/mixins";
@import "styles/breakpoints";

.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 65px;
  @include media("desktop-small") {
    padding-top: 70px;
  }
}
