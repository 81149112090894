@import "styles/variables";
@import "styles/mixins";

.title {
  margin: $spacing-10 0;

  @include media("tablet-landscape") {
    margin: $spacing-15 0;
  }
}
