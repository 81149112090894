@import "styles/variables";
@import "styles/mixins";

.sectionTitle {
  margin-bottom: $spacing-6;

  @include media("tablet-landscape") {
    margin-bottom: $spacing-10;
  }
}

.section {
  padding: $spacing-10 0;

  &.noResults {
    padding: 0;
  }

  @include media("tablet-landscape") {
    padding: $spacing-15 0;
  }
}

.classesContainer {
  margin: 0 -#{$spacing-7};
}
