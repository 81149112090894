@import "styles/colors";
@import "styles/mixins";
.wrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-default-khaki;
  color: $color-primary-action;
  font-size: 30px;
  z-index: 200;
  @include media("mobile-landscape") {
    display: none;
  }
}