@import "styles/variables";
@import "styles/mixins";

.header {
  margin: $spacing-10 0 $spacing-4;
  @include media("tablet-landscape") {
    margin: $spacing-15 0 $spacing-7;
  }
}

.tab {
  margin-right: $spacing-10;
}

.icon {
  display: none;
}

.activeTab {
  .icon {
    display: inline;
  }
  .tabTitle {
    color: var(--color-primary-action-text);
  }
}
