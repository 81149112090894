@import "styles/variables";

.feedbackModal {
  padding: 0 !important;
}

.wrapper {
  padding: $spacing-5;
  text-align: center;
}

.separator {
  background-color: var(--color-separator);
  height: 1px;
}

.caption {
  margin-bottom: $spacing-4;
}

.buttons {
  display: flex;
  align-items: center;

  .button {
    flex: 1;
    &:first-child {
      margin-right: $spacing-6;
    }
  }
}
