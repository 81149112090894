@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltipText {
  display: none;
  width: 220px;
  background-color: #fff;
  color: $color-default-khaki;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  line-height: 1.27;
  position: absolute;
  z-index: 1;
  top: -30px;
  left: 140%;
  box-shadow: 1px 2px 8px 0px rgba(34, 60, 80, 0.2);
  font-size: 14px;
}

.tooltip:hover .tooltipText {
  display: inline-block;
}
