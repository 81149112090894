%button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border: solid 1px #dadbd9;
  border-radius: 3px;
  padding: 9px;
}

.facebook {
  @extend %button;
  color: #fff;
  background-color: #1877f2;
  border: solid 1px #1877f2;
}

.label {
  display: block;
  padding: 0 30px;
}

.google {
  @extend %button;
  color: rgba(0, 0, 0, 0.7);
}

.icon {
  margin-right: 10px;
  font-size: 24px;
  position: absolute;
  left: 11px;
}

