@import "styles/mixins";
@import "styles/variables";

$max-banner-height: 590px;

.imageWrapper {
  padding-bottom: 42%;

  @include media("desktop-medium") {
    padding-bottom: 0;
    height: $max-banner-height;
  }
}

.bannerInContent {
  filter: drop-shadow(0px 0px 16px rgba(84, 104, 92, 0.2));
}
