@import "styles/variables";
@import "styles/mixins";


$color-background: rgba(47, 60, 52, 0.75);
$color-text: #fff;
$color-close: rgba(255, 255, 255, 0.6);


.wrapper {
  position: fixed;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-background;
  padding: 17px 24px;
  border-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: auto;
  margin-right: auto;
  top: 70px;
  width: 80%;
  transition: all 0.5s linear;
  z-index: 50;
  @include media("tablet-landscape") {
    transform: none;
    left: auto;
    right: 20px;
    top: 132px;
    width: auto;
    animation: rightToLeft;
    animation-duration: 0.3s;

  }
}

.message {
  color: $color-text;
  font-size: 15px;
  font-weight: 500;
  margin-right: 20px;
}

.close {
  color: $color-close;
  display: block;
  min-width: 16px;
  min-height: 16px;
  cursor: pointer;

}

@keyframes rightToLeft {
  from {
    transform: translateX(100%);
    display: none;

  }
  to {
    transform: translateX(0);
    display: block;
  }
}




