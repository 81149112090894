@import "styles/variables";
@import "styles/mixins";

.link {
  text-decoration: none;
}

.itemWrapper {
  position: relative;
  margin: 0 $spacing-3 / 2;

  @include media("tablet-landscape") {
    margin: 0 $spacing-3;
  }

  .imageWrapper {
    margin-bottom: $spacing-6;
    padding-top: $image-aspect-ratio-3-to-2;
    overflow: hidden;
    position: relative;

    .image {
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
    }
  }

  .itemContent {
    .tag {
      color: var(--color-fill-3);
      margin-bottom: $spacing-1;
    }
  }
}
