@import "styles/mixins";
@import "styles/variables";
@import "styles/colors";

.content {
  padding: 20px 20px;
  height: 93%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include media("tablet-portrait") {
    padding: 20px 50px;
  }
}

.empty {
  text-align: center;
  color: $color-default-khaki;
  font-size: 22px;
}

.basketItems {
  margin-bottom: 20px;
  overflow-y: auto;
  height: 90%;
}

.shipping {
  color: $color-default-khaki;
  font-size: 18px;
  margin-bottom: 20px;
}

.promotion {
  display: flex;
  justify-content: space-between;
  color: $color-default-khaki;
  font-size: 18px;
  margin-bottom: 20px;
}

.total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 22px;
  font-weight: 500;
  color: $color-default-khaki;
}

.checkout {
  margin-bottom: 10px;
}