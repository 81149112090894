@import "styles/variables";
@import "styles/mixins";

.container {
  margin-bottom: $spacing-10;

  @include media("tablet-portrait-max") {
    padding: 0;
  }

  @include media("tablet-landscape") {
    margin-bottom: $spacing-12;
  }
}

.item {
  margin: 0;

  @include media("tablet-landscape") {
    margin: 0 $spacing-3;
    margin-bottom: $spacing-6;
  }
}

.itemsContainer {
  @include media("tablet-landscape") {
    margin: 0 -$spacing-3;
  }
}
