@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.accountBox {
  background-color: $color-white;
  padding: $spacing-7;
  min-height: 100vh;
  //display: flex;
  @include media("tablet-portrait") {
    min-width: 600px;
    position: absolute;
    right: $spacing-8;
    top: 50%;
    transform: translateY(-50%);
    min-height: 759px;
  }
}
