@import "styles/variables";
@import "styles/mixins";

.title {
  margin-bottom: $spacing-6;
  text-align: left;
}

.sessions {
  .row {
    border-bottom: solid 1px var(--color-separator);

    & > div {
      padding-top: $spacing-4;
      padding-bottom: $spacing-4;
      display: flex;
      align-items: center;
      text-align: left;

      &:first-child {
        padding-left: 0 !important;
      }
      &:last-child {
        padding-right: 0 !important;
      }
    }
    .action {
      justify-content: flex-end;
    }
  }
}

.sessionCardMobile {
  border-bottom: solid 1px var(--color-separator);
  padding: $spacing-6 0;
  text-align: left;

  &:first-child {
    border-top: solid 1px var(--color-separator);
  }
  .col2 {
    text-align: right;
  }
  & > div {
    margin-bottom: $spacing-2;
  }
  .button {
    margin-top: $spacing-4;
  }
  .date {
    color: var(--color-fill-3);
  }
}

.cursor {
  cursor: pointer;
}
