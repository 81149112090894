.snipcart-cart-summary {
  margin-top: $spacing-8;

  .snipcart-cart-summary__actions {
    display: flex;
    button {
      display: none;
    }
  }

  .snipcart-featured-payment-methods__title {
    text-align: center;
    margin-top: $spacing-4;

    a {
      color: var(--color-fill-2);
      font-size: pxToRem(15);
      font-weight: 500;

      svg {
        width: 1rem;
        height: 1rem;
        margin-right: $spacing-2;
        background-image: url("../icons/lock.svg");
        background-repeat: no-repeat;
        background-position: center;

        path {
          display: none;
        }
      }
    }
  }
}

.snipcart-cart-summary-items-list {
  list-style: none;
  padding: 0;
  margin: $spacing-4 0;
  padding-bottom: $spacing-2;
  border-bottom: 1px solid var(--color-separator);

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-4;
    span,
    div {
      width: calc(100% / 3);
      @extend %subtitle;

      &:last-of-type {
        text-align: right;
      }

      &:nth-last-of-type(2) {
        text-align: center;
      }
    }

    .snipcart-cart-summary-item__quantity {
      display: none;
    }
  }
}

.snipcart-cart-summary__footer {
  margin-top: $spacing-4;
  border-top: 1px solid var(--color-separator);
}
