@import "styles/mixins";
@import "styles/variables";

.productInfo {
  background: var(--color-background-2);
  padding: $spacing-6;
  border-bottom: 1px solid var(--color-separator);

  .infoRow {
    display: flex;
    align-items: center;
    color: var(--color-fill-1);

    &:not(:last-of-type) {
      margin-bottom: $spacing-4;
    }

    .icon {
      font-size: pxToRem(24);
      margin-right: $spacing-4;
    }
  }
}

.productPrice {
  background: var(--color-background-2);
  padding: $spacing-6;

  .price {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-3;

    .label {
      color: var(--color-fill-3);
    }
  }
}

.productAlert {
  padding: $spacing-2 $spacing-10;
  text-align: center;
  background: var(--color-semantics-warning);
}
