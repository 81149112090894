@import "styles/variables";
@import "styles/mixins";

$max-banner-height: 700px;

.wrapper {
  overflow: hidden;
  display: none;
  @include media("tablet-landscape") {
    display: flex;
  }
}

.content {
  position: relative;
  width: 100%;
  display: flex;

  .subtitle {
    text-align: center;
    position: absolute;
    top: 70%;
    z-index: 2;
    left: 0;
    right: 0;
  }

  .overlay {
    display: block;
    position: absolute;
    z-index: 1;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    div{
      width: 58.3333%;
      margin: 0 auto;
    }
    @include media("mobile-landscape-max") {
      display: none;
    }
  }

  @include media("desktop-medium") {
    //padding-top: 0;
    max-height: $max-banner-height;
  }


}


.mobileSection {
  display: none;
  padding: 3vh 0;
  text-align: center;

}

@include media("mobile-landscape-max") {
  .mobileSection {
    display: block;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.video {
  z-index: 1;
  width: 100%;
  object-fit: cover;
}
