@import "styles/variables";
@import "styles/mixins";
@import "styles/helpers";

$container-spacing-tablet: $spacing-6;
$container-spacing-desktop: $spacing-10;

.container {
  @extend %full-height;
  width: 100vw;
  overflow: hidden;
  position: relative;

  @include media("tablet-landscape") {
    padding: $container-spacing-tablet;
  }

  @include media("desktop-medium") {
    padding: $container-spacing-desktop;
  }
}

.content {
  @extend %full-height;
  z-index: 2;

  @include media("tablet-landscape") {
    height: calc(100vh - #{$container-spacing-tablet} * 2);
    width: 460px;
  }
  @include media("desktop-medium") {
    height: calc(100vh - #{$container-spacing-desktop} * 2);
  }
}

.clouds {
  @extend %full-height;
  width: 100vw;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  object-fit: cover;
}

.gradient {
  mask-image: url(../../../../assets/images/mask-logo-new.svg);
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top center;
  object-fit: fill;

  z-index: 1;
  position: absolute;
  top: calc(50% + 252.5px);
  left: calc(50% + 243px);
  transform: translate(-50%, -50%);

  width: 784px;
  height: 641px;

  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 3s;
  animation-delay: 100ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  @include media("desktop-medium") {
    left: calc(50% + 250px);
  }
  @include media("desktop-height-small") {
    top: auto;
    bottom: -10%;
    transform: translate(-50%, 0);
    width: auto;
    height: 60%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
