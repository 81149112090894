@import "styles/variables";
@import "styles/colors";
@import "styles/mixins";

.promo {
  margin-bottom: 30px;
  margin-top: auto;

}

.title {
  font-size: 17px;
  color: $color-default-khaki;
  margin-bottom: 5px;
  font-weight: 500;
}

.apply {
  color: $color-khaki-light;
}

.isGold {
  div {
    color: $color-primary-action;
  }
}

.promoForm {
  width: 100%;
  display: flex;
  align-items: center;
}

.input {
  width: 60%;
  @include media("mobile-landscape") {
    width: 250px;
  }
  border: 2px solid lightgray;
  padding: 6px 10px;
  font-size: 16px;
  color: var(--color-default-khaki);

  &::placeholder {
    color: var(--color-default-khaki)
  }
}

.filled {
  background-color: $color-khaki-extra-light;
}

.cancel {
  color: var(--color-primary-action-text)
}

.error {
  position: absolute;
  color: tomato;
}