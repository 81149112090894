@import "styles/variables";
@import "styles/mixins";

.container {
  background: var(--color-background-1);
  border-top: thin solid var(--color-separator);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  align-items: baseline;
  padding: $spacing-4;
}

.content {
  flex: 1 0 100%;
  margin-bottom: $spacing-2;

  @include media("mobile-landscape") {
    flex: 1;
    margin-bottom: 0;
    margin-right: $spacing-4;
  }
}
