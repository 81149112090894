@import "styles/variables";
@import "styles/mixins";

$modal-width-tablet: 775px;
$modal-width-tablet: 810px;

.modal {
  width: $modal-width-tablet;

  @include media("desktop-small") {
    width: $modal-width-tablet;
  }
}
.header {
  display: flex;
  align-items: center;

  .clearButton {
    margin-left: $spacing-4;
  }
}

.filterGroup {
  margin: $spacing-12 0;
}

.groupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.option {
  margin: $spacing-4 0;
}

.content {
  overflow: auto;
}
