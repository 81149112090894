@import "styles/variables";
@import "styles/mixins";

%third {
  background: linear-gradient(
    to bottom,
    var(--color-background-1-reverse) 40%,
    var(--color-background-1) 40%,
    var(--color-background-1) 60%
  );
}

%quarter {
  background: linear-gradient(
    to bottom,
    var(--color-background-1-reverse) 20%,
    var(--color-background-1) 20%,
    var(--color-background-1) 80%
  );
}

%half {
  background: linear-gradient(
    to bottom,
    var(--color-background-1-reverse) 50%,
    var(--color-background-1) 50%
  );
}

.container {
  margin: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 0;
  color: var(--color-fill-1);
  background-color: var(--color-background-1);

  &.fullHeight {
    height: 100%;
  }

  &.withSeparatorSpacing {
    padding: $section-vertical-spacing-mobile/2 0;

    + .withSeparatorSpacing:last-child {
      padding-bottom: $section-vertical-spacing-mobile * 2;
    }

    @include media("tablet-landscape") {
      padding: $section-vertical-spacing-desktop/2 0;

      + .withSeparatorSpacing:last-child {
        padding-bottom: $section-vertical-spacing-desktop * 2;
      }
    }
  }

  &:global(.khaki-theme) + :global(.white-theme) {
    &:not(.noBackgroundOverlap) {
      color: var(--color-fill-1-reverse);

      &.quarter {
        @extend %quarter;
      }

      &.half {
        @extend %half;
      }

      &.third {
        @extend %third;
      }
    }
  }

  &:global(.white-theme) + :global(.khaki-theme) {
    &:not(.noBackgroundOverlap) {
      color: var(--color-fill-1-reverse);

      &.quarter {
        @extend %quarter;
      }

      &.half {
        @extend %half;
      }

      &.third {
        @extend %third;
      }
    }
  }
}
