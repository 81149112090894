@import "styles/variables";
@import "styles/mixins";

.footerWrapper {
  background: var(--color-background-2);
}

.footer {
  padding: $spacing-10 0 0;

  @include media("tablet-landscape") {
    padding: $spacing-20 0 $spacing-15;
  }

  .title {
    color: var(--color-fill-2);
  }
}

.bottomFooter {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @include media("tablet-landscape") {
    height: $spacing-12;
    flex-direction: row;
    align-items: center;
  }
}
