.snipcart-cart__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacing-6 $spacing-4;

  @include media("tablet-landscape") {
    padding: $spacing-10 $spacing-15;
  }

  .snipcart-item-list {
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 1;

    .snipcart-item-line {
      margin-bottom: $spacing-8;

      .snipcart-item-line__container {
        display: flex;

        .snipcart-item-line__product {
          width: 100%;
          position: relative;

          .snipcart-item-line__header {
            display: flex;
            padding-right: $spacing-16;
            justify-content: space-between;

            .snipcart-item-line__title {
              color: var(--color-fill-1);
              font-weight: 500;
              margin-bottom: $spacing-1;
              font-size: pxToRem(22);
              line-height: 1.272727273;
            }

            .snipcart-item-line__actions {
              position: absolute;
              bottom: 0;
              right: 0;

              @include media("tablet-landscape") {
                bottom: $spacing-1;
              }
              .snipcart__icon {
                background-image: url("../icons/delete.svg");
                background-repeat: no-repeat;
                background-position: center;
                path {
                  display: none;
                }
              }
            }
          }

          .snipcart-item-line__body {
            display: flex;
            padding-right: $spacing-16;

            .snipcart-item-custom-fields__field {
              font-weight: 400;
              font-size: pxToRem(15);
              line-height: pxToRem(20);
              color: var(--color-fill-1);

              label {
                display: none;
              }

              &:first-child {
                margin-bottom: $spacing-2;
              }
            }

            .snipcart-item-description {
              font-weight: 400;
              font-size: pxToRem(15);
              line-height: pxToRem(20);
              color: var(--color-fill-1);
            }

            .snipcart-item-line__quantity {
              position: absolute;
              top: $spacing-1;
              right: 0;
            }
          }
        }

        .snipcart-item-line__media {
          margin: 0;
          width: 85px;
          height: 60px;
          overflow: hidden;
          margin-right: $spacing-4;
          display: none;

          @include media("tablet-landscape") {
            display: block;
          }

          .snipcart-item-line__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.snipcart-item-quantity__label {
  display: none;
}

.snipcart-item-quantity__quantity {
  display: none;
}

.snipcart-item-quantity__total-price {
  @extend %subtitle;
}

.snipcart-featured-payment-methods__list {
  display: none;
}

.snipcart-cart-summary-fees__item {
  margin-bottom: $spacing-2;
  display: flex;
  justify-content: space-between;
}

.snipcart-cart-summary-fees__notice {
  margin-bottom: $spacing-4;
  display: flex;
  justify-content: space-between;
  @extend %subtitle;
  color: var(--color-fill-2);
}

.snipcart-cart-summary-fees__title {
  @extend %subtitle;
  color: var(--color-fill-2);

  &.snipcart__font--large {
    font-size: pxToRem(22);
    line-height: 1.272727273;
    color: var(--color-fill-1);
  }
}

.snipcart-cart-summary-fees__amount {
  @extend %subtitle;
  color: var(--color-fill-2);

  &.snipcart__font--large {
    color: var(--color-fill-1);
  }
}

.snipcart-cart-button {
  background: var(--color-primary-action);
  color: var(--color-on-primary-action);
  padding: $spacing-2 $spacing-5;
  margin-top: $spacing-6;
  @extend %title3;
  @extend %large;
  width: 100%;

  @include media("tablet-landscape") {
    width: initial;
  }

  svg {
    fill: var(--color-on-primary-action);
    path {
      fill: var(--color-on-primary-action);
    }
  }
}

.snipcart-cart__footer {
  .snipcart-cart-button {
    svg {
      margin-left: $spacing-4;
    }
  }
}

.snipcart-empty-cart {
  text-align: center;
  padding: $spacing-15;

  h1 {
    color: var(--color-fill-1);
    @extend %title1;
    @extend %large;
    font-weight: 500;
  }

  .snipcart-cart-button {
    svg {
      display: none;
    }
  }
}

.snipcart-cart__footer-col {
  .snipcart-cart__checkout-button {
    width: 100%;

    svg {
      display: none;
    }
  }
}

.snipcart-cart-summary-fees__discount-name {
  display: inline;
  &:after {
    content: ", ";
  }
  &:last-child:after {
    content: "";
  }
}
