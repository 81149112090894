@import "styles/colors";
@import "styles/variables";

.container {
  padding: 0 $spacing-5 $spacing-8;
  text-align: center;
}

.iconWrapper {
  background: var(--color-semantics-warning);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing-2;
  border-radius: 50%;

  .icon {
    font-size: $spacing-6;
    color: var(--color-on-primary-action);
  }
}

.button {
  background: var(--color-semantics-warning);
}

.header {
  padding-top: $spacing-6;
  margin-bottom: $spacing-2;
}
