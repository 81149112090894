@import "styles/colors";

.wrapper {
  height: 100vh;
  //background-image: url("../../../../assets/images/clouds.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.header {
  margin-bottom: 90px;
}

.arrow {
  position: absolute;
  font-size: 22px;
  cursor: pointer;
  color: $color-primary-action;
}


.logo {
  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    transform: scale(1.3);
  }
}


.title {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  color: $color-default-khaki;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 16px;
  max-width: 520px;
  text-align: center;
  color: $color-default-khaki;
  margin-bottom: 30px;
  line-height: 1.2;
  margin-left: auto;
  margin-right: auto;
}

.explanation {
  font-size: 19px;
  text-align: center;
  color: $color-default-khaki;
  margin-bottom: 80px;
  line-height: 1.2;
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
}

.check {
  font-size: 16px;
  text-align: center;
  color: $color-default-khaki;
  margin-bottom: 20px;
  line-height: 1.2;
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
}

.button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}