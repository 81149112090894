@import "styles/variables";
@import "styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin-bottom: $spacing-3;
  padding: $spacing-2 $spacing-3;
  border-left: 2px solid var(--color-background-2);

  @include media("tablet-landscape") {
    position: absolute;
    flex-direction: row;
    margin: 0;
    padding: 0;
    margin-top: $spacing-10;
    border: none;
  }

  @include media("desktop-small") {
    justify-content: center;
  }

  .navItem {
    margin-bottom: $spacing-4;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
