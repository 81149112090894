@font-face {
  font-family: "Brandon Grotesque";
  src: url("../fonts/brandon-grotesque-light.woff2") format("woff2"),
    url("../fonts/brandon-grotesque-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("./brandon-grotesque-regular.woff2") format("woff2"),
    url("./brandon-grotesque-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("./brandon-grotesque-medium.woff2") format("woff2"),
    url("./brandon-grotesque-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("./brandon-grotesque-bold.woff2") format("woff2"),
    url("./brandon-grotesque-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
