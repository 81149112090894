@import "styles/variables";
@import "styles/mixins";

.navWrapper {
  display: flex;
  flex-direction: column;
  widows: 0;

  @include media("tablet-landscape") {
    flex: 2;
    justify-content: flex-start;
    position: relative;
    padding: $spacing-1 $spacing-6;
  }

  @include media("desktop-small") {
    justify-content: center;
    padding: 0;
    padding-top: $spacing-1;
  }
}

.navLinks {
  list-style: none;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media("tablet-landscape") {
    padding: 0;
    flex-direction: row;
  }

  @include media("desktop-small") {
    justify-content: center;
  }
}
