@import "styles/variables";
@import "styles/mixins";

.socialLinks {
  padding: 0;
  margin: 0;
  margin-bottom: $spacing-10;
  list-style: none;
  display: flex;

  @include media("tablet-landscape") {
    margin-bottom: 0;
  }
}
