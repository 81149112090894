@import "styles/variables";
@import "styles/mixins";

.subtitle {
  margin-bottom: $spacing-4;
}

.title {
  margin-bottom: $spacing-6;
  color: currentColor;

  @include media("tablet-landscape") {
    margin-bottom: 0;
  }
}

.sessionSlide {
  margin: 0 $spacing-3 / 2;

  @include media("tablet-portrait") {
    margin: 0 $spacing-3;
  }
}

.sessionsContainer {
  padding-top: $spacing-12;
}

.sessionsSwiper {
  padding-bottom: $spacing-12;
}
