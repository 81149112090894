@import "styles/variables";
@import "styles/mixins";

.controls {
  padding: $spacing-3;
  display: flex;
  justify-content: center;
}

.input {
  color: var(--color-fill-1);
  background-color: var(--color-background-1);
  border: none;
  font-size: pxToRem(17);
  font-weight: 500;
  flex: 1;
  outline: 0;
}

.actionButton {
  color: var(--color-primary-action-text);
}

.disabledText {
  color: var(--color-fill-3);
  padding: $spacing-6;
}
