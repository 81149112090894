@import "styles/variables";
@import "styles/mixins";
@import "styles/helpers";

$sidebar-animation: 0.2s ease-out;

.fullBody {
  position: fixed;
  overflow: hidden;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  height: -moz-available;
  height: 100%;

  @include media("tablet-landscape") {
    flex-direction: row;
  }

  &.isFullscreen {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    min-height: -moz-available;
  }

  &:not(.isFullscreen) {
    @include media("tablet-portrait-max", "landscape") {
      .content {
        display: none;
      }
    }
  }
}

.content {
  position: relative;
  flex: 1;
  transition: all $sidebar-animation;

  .isFullscreen & {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    min-height: -moz-available;

    @include media("tablet-landscape") {
      margin-right: -$video-sidebar-width-tablet;
    }
    @include media("desktop-small") {
      margin-right: -$video-sidebar-width-desktop;
    }
  }
}

.main {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media("tablet-landscape") {
    padding: $video-toolbar-height-desktop $spacing-11;
  }

  .isFullscreen & {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    min-height: -moz-available;
  }
}

.bottomToolbar {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  padding: $video-toolbar-spacing-mobile;

  @include media("tablet-landscape") {
    padding: $video-toolbar-spacing-desktop;
  }

  // Spacing between buttons in bottom toolbar
  > div {
    margin: 0 -#{$spacing-1};
    > * {
      margin: 0 #{$spacing-1};
    }

    @include media("tablet-landscape") {
      margin: 0 -#{$spacing-2};
      > * {
        margin: 0 #{$spacing-2};
      }
    }
  }
}

.sidebar {
  height: 100%;
  transition: transform $sidebar-animation;

  @include media("tablet-portrait-max") {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  @include media("tablet-landscape") {
    flex: 0 0 #{$video-sidebar-width-tablet};
  }

  @include media("desktop-small") {
    flex: 0 0 #{$video-sidebar-width-desktop};
  }

  .isFullscreen & {
    transform: translateY(100%);

    @include media("tablet-landscape") {
      transform: translateX(100%);
    }
  }
}
