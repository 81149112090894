@import "styles/variables";
@import "styles/mixins";

.wrapper {
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;

  .logo {
    margin-bottom: $spacing-9;
  }

  .title {
    margin-bottom: $spacing-9;
  }

  .bookingRef {
    margin-bottom: $spacing-2;
  }

  .date {
    margin-bottom: $spacing-9;
    color: var(--color-fill-3);
  }

  .description {
    margin-bottom: $spacing-9;

    a {
      color: inherit;
    }
  }

  .button {
    margin-bottom: $spacing-6;
  }

  .bottomText {
    color: var(--color-fill-3);
  }

  .illustration {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/images/Aura.svg");
    background-repeat: no-repeat;
    background-size: 140%;
    background-position: top;

    @include media("tablet-portrait") {
      background-size: 75%;
      background-position: center;
    }
  }
}
