@import "styles/variables";
@import "styles/mixins";

.container {
  display: flex;
  align-items: center;
  padding: $spacing-6 0;

  @include media("tablet-landscape") {
    padding: $spacing-15 0;
  }
}

.icon {
  font-size: $icon-size-s;
  color: var(--color-primary-action-text);
  margin-right: $spacing-4;
}

.inputWrapper {
  flex: 1;
}

.input {
  white-space: pre-line;
  word-break: break-word;
  padding: 0;
  margin: 0;
  color: var(--color-fill-1);
  font-weight: 400;
  font-size: pxToRem(21);
  line-height: pxToRem(28);
  border: none;

  @include media("tablet-landscape") {
    font-size: pxToRem(30);
    line-height: pxToRem(40);
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
}
