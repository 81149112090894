@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.wrapper {
  background-image: url("../../../../assets/images/clouds.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.row {
  margin-bottom: $spacing-8;
}

.button {
  margin-top: $spacing-10;
}

.header {
  font-size: 22px;
  letter-spacing: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  margin-bottom: $spacing-7;
}

.paddingTop {
  padding-top: 80px;
  @include media("mobile-landscape") {
    padding-top: 0;
  }
}

.back {
  color: $color-primary-action;
  cursor: pointer;
  height: $spacing-6;
  width: $spacing-6;
  display: none;
  @include media("mobile-landscape") {
    display: flex;
  }
}

.first {
  margin-bottom: $spacing-6;
  @include media("tablet-portrait") {
    padding-right: 5px;
    margin-bottom: 0;
  }
}

.second {
  @include media("tablet-portrait") {
    padding-left: 5px;
  }
}

.label {
  margin-left: 8px;
  width: 16px;
  height: 16px;
  fill: $color-light-gray;
  cursor: pointer;
}

.forgot {
  color: $color-default-khaki;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 10px;
  @include media("tablet-portrait") {
    margin-bottom: 0;
  }
}

.wrapperForgot {
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse;
  @include media("tablet-portrait") {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }
}