@import "styles/colors";

.label {
  display: block;
  font-size: 17px;
  color: $color-default-khaki;
  margin-bottom: 4px;
  font-weight: 500;
}

.select {
  background: url("/ic-dropdown.png") no-repeat center right 15px;

}