@import "styles/variables";
@import "styles/mixins";

.subtitle {
  margin-bottom: $spacing-2;
  text-align: center;

  @include media("tablet-landscape") {
    margin-bottom: $spacing-4;
    text-align: left;
  }
}

.title {
  margin-bottom: $spacing-1;
  color: currentColor;
  text-align: center;

  @include media("tablet-landscape") {
    margin-bottom: 0;
    text-align: left;
  }
}

.sessionSlide {
  margin: 0 $spacing-3 / 2;

  @include media("tablet-portrait") {
    margin: 0 $spacing-3;
  }
}

.sessionsContainer {
  padding-top: $spacing-6;

}

.sessionsSwiper {
  padding-bottom: $spacing-2;
  @include media("tablet-portrait") {
    padding-bottom: $spacing-8;
  }

}


