@import "styles/variables";

.message {
  min-height: $spacing-8;
  padding: $spacing-2 0;
  color: var(--color-semantics-warning);
}
.error {
  color: var(--color-semantics-warning);
}
.success {
  color: var(--color-fill-1);
}
