@import "styles/variables";
@import "styles/mixins";

.category {
  color: var(--color-fill-3);
  margin-bottom: $spacing-2;
}

.title {
  margin-bottom: $spacing-10;
}

.link {
  color: var(--color-primary-action-text);
  vertical-align: initial;
  font-size: inherit;
}

.subheader {
  margin-bottom: $spacing-2;
}

.tagsWrapper {
  margin-bottom: $spacing-14;

  @include media("tablet-landscape") {
    margin-bottom: $spacing-10;
  }

  //.tag {
  //  margin-right: $spacing-2;
  //  margin-bottom: $spacing-2;
  //}
}

.description {
  margin-bottom: $spacing-6;

  .link {
    margin: 0 $spacing-2;
  }
}
