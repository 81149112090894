@import "styles/variables";

.header {
  padding: $spacing-6;
}

.title {
  color: var(--color-fill-3);
  margin-bottom: $spacing-1;
}
