@import "styles/variables";

$radio-circle-size: 24px;

.wrapper {
  position: relative;
  display: flex;
  margin-bottom: $spacing-6;
  min-height: $spacing-6;
  align-items: center;

  .radio {
    height: $radio-circle-size;
    left: 0;
    display: none;
    top: 0;
    width: $radio-circle-size;
  }

  .label {
    cursor: pointer;
    width: 100%;
  }

  &:not(.hasIcon) {
    .label {
      padding-left: $spacing-9;
    }

    .radio + .label::before {
      border: 1px solid var(--color-separator);
      border-radius: 50%;
      bottom: 0;
      content: "";
      height: $radio-circle-size;
      left: 0;
      margin: auto;
      position: absolute;
      top: 0;
      width: $radio-circle-size;
    }

    .radio + .label::after {
      content: "";
      opacity: 0;
      width: $spacing-4;
      height: $spacing-4;
      background: var(--color-primary-action);
      border-radius: 50%;
      position: absolute;
      left: $spacing-1;
      top: $spacing-1;
    }

    .radio:checked + .label::after {
      opacity: 1;
    }
  }

  .radioIcon {
    width: 56px;
    height: 56px;
    font-size: $spacing-6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-separator);
  }
}
