@import "styles/variables";
@import "styles/mixins";

.content {
  overflow-y: scroll;
  flex: 1;
}

.userContainer {
  padding: $spacing-2 $spacing-6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .delete {
    display: none;
  }

  &.hoverable:hover {
    .delete {
      display: inline;
    }
    .date {
      display: none;
    }
  }
}

.user {
  display: flex;
  align-items: center;
}

.avatar {
  margin-right: $spacing-2;
}

.comment {
  margin-top: $spacing-3;
  margin-left: $spacing-8;
  flex: 1 1 100%;
}

.loading {
  margin-bottom: $spacing-24;

  @include media("tablet-landscape") {
    margin-bottom: 0;
  }

  &.loadingHide {
    opacity: 0;
    visibility: hidden;
  }
}

.noChat {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing-10;

  .privatePlaceholder {
    color: var(--color-fill-3);
    text-align: center;
  }
}
