.leftWrapper {
  display: flex;
  flex-direction: column;

  :global(#snipcart-checkout-step-payment) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
