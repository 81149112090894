@import "styles/colors";

.wrapper {
  margin-bottom: 32px;
  color: $color-default-khaki
}

.title {
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.name {
  margin-bottom: 10px;
  font-size: 17px;
  color:$color-khaki-light;
}

.email {
  margin-bottom: 10px;
  font-size: 17px;
  color:$color-khaki-light;
}