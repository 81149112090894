@import "styles/variables";
@import "styles/mixins";

.wrapper {
  display: flex;
  background-color: #65776d;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 $spacing-3 / 2;
  cursor: pointer;
  max-height: 276px;

  @include media("tablet-landscape") {
    margin: 0 $spacing-3;
    height: auto;
    max-height: none;
  }

}

.text {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  text-align: center;
  color: #d9b677;
  text-transform: uppercase;
  padding: 140px 0;

  @include media("tablet-portrait") {
    padding: 150px 0;
  }
}