@import "styles/variables";
@import "styles/mixins";

.container {
  width: 100%;
  padding-bottom: 1em;
  @include media("desktop-medium") {
    width: 70%;
    padding-bottom: 2em;
  }
}

.title {
  text-align: center;
  font-size: 2em;
  padding-top: 1.5em;
  padding-bottom: 1em;
}

.titleText {
  font-size: 1.5rem;
}

.link {
  display: flex;
  align-items: center;
}

.logo {
  max-width: 100%;
  height: auto;
  padding: 10px;
}