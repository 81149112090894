@import "styles/colors";
@import "styles/variables";

.container {
  padding: 0 $spacing-5 $spacing-5;
  text-align: center;
}

.iconWrapper {
  background: var(--color-primary-action-text);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing-2;
  border-radius: 50%;

  .icon {
    font-size: $spacing-6;
    color: var(--color-on-primary-action);
  }
}

.caption {
  margin-bottom: $spacing-10;
}

.buttons {
  display: flex;
  align-items: center;

  .button {
    flex: 1;
    &:first-child {
      margin-right: $spacing-5;
    }
  }
}
