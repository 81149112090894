@import "styles/variables";

.footerLinkItem {
  &:not(:last-of-type) {
    margin-bottom: $spacing-3;
  }
  .subtitle {
    color: var(--color-fill-1);
  }
  .link {
    text-decoration: none;
  }
}
