@import "styles/variables";
@import "styles/mixins";

.container {
  margin-top: $spacing-10;
  margin-bottom: $spacing-10;

  :global(.rc-collapse-item-active) {
    .tabTitle {
      color: var(--color-primary-action-text);
    }
  }
}

.tagLine {
  margin-top: $spacing-6;
  margin-bottom: $spacing-4;
}

.title {
  margin-bottom: $spacing-12;

  @include media("tablet-landscape") {
    margin-bottom: $spacing-10;
  }
}

.link {
  margin-top: $spacing-4;
  color: var(--color-primary-action-text);
  text-decoration: underline;

  h6 {
    text-transform: capitalize;
    text-decoration: underline;
  }
}

.image {
  padding-bottom: $image-aspect-ratio-3-to-2;
}

.desktopTabTitle {
  margin-bottom: $spacing-4;
  cursor: pointer;
}
