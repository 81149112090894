@import "styles/variables";
@import 'styles/colors';
@import 'styles/mixins';

.row {
  margin-bottom: 50px;
}

.secondRow {
  margin-top: auto;
}

.image {
  margin-top: 100px;
  margin-bottom: 10px;

  svg {

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.title {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.92px;
  text-align: center;
  color: $color-default-khaki;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.subtitle {
  font-size: 17px;
  letter-spacing: 1px;
  text-align: center;
  color: $color-default-khaki;
}


.controls {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}


.modalText {
  padding: 0 20px 48px 20px;
  margin-top: -30px;
  font-size: 17px;
  text-align: center;
  line-height: normal;
  color: $color-default-khaki;
}

:global(.waiverError) {
  display: none;
  margin-top: 5px;
  color: red;
}

:global(.waiverError):global(.show){
  display: block;
}


:global(.plain-checkbox) {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    a{
      margin: 5px;
    }
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(84, 104, 92, 0.15);
  }

  // Box hover
  &:hover + label:before {
    background: #b29164;
  }

  // Box checked
  &:checked + label:before {
    background: #b29164;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
