@import "styles/variables";
@import "styles/mixins";

.wrapper {
  background-color: #65776d;
  width: 100%;
  margin-top: 20px;


  @include media("tablet-landscape") {
    width: 22%;
    align-self: flex-start;
    margin-top: 52px;
    margin-left: 24px;
  }
}

.timings {
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.35);
  font-size: 15px;
  font-weight: 500;
  display: none;

  @include media("tablet-landscape") {
    display: block;
  }
}

.time:not(:last-child) {
  margin-bottom: 16px;
}

.prices {
  padding: 24px;
}

.priceSection {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 16px;
}


.subtitle {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #d9b677;
  text-transform: uppercase;
  white-space: nowrap;
  margin-right: 10px;
}
.titles {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.time_zone {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.75);
}