.wrapper {
  display: flex;
  height: 100%;
}

.video {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image {
  width: 100%;
  object-fit: cover;
  max-height: 400px;
}