@import "styles/variables";
@import "styles/mixins";

%effect {
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content {
  opacity: 0;
  @extend %effect;
  animation-name: rcDialogZoomIn;
}

.ReactModal__Content--before-close {
  @extend %effect;
  animation-name: rcDialogZoomOut;
}

@keyframes rcDialogZoomIn {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes rcDialogZoomOut {
  0% {
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform: scale(0, 0);
  }
}

%fade-effect {
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

.ReactModal__Overlay {
  opacity: 0;
  @extend %fade-effect;
  animation-name: rcDialogFadeIn;
}

.ReactModal__Overlay--before-close {
  @extend %effect;
  animation-name: rcDialogFadeOut;
}

@keyframes rcDialogFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
