@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.wrapper {
  max-width: 450px;
  color: white;
  padding: 30px;
  background-color: var(--color-background-1);

  @include media("tablet-portrait") {
    margin: 0 100px;
  }

  &.imagery {
    background-color: transparent;
  }

  &.white {
    background-color: #ffffff;
  }

  &.khaki {
    background-color: #54685c;
  }

  &.orange {
    background-color: #ff6d2d;
  }

  &.green {
    background-color: #006548;
  }

  &.yellow {
    background-color: #ffe000;
  }


}

.subtitle {
  text-align: center;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--color-fill-1);
  margin-bottom: 18px;
  text-transform: uppercase;
  @include media("tablet-portrait") {

  }
}

.title {
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.2;
  text-align: center;
  color: var(--color-fill-1);
  margin-bottom: 16px;
  text-transform: uppercase;
  @include media("tablet-portrait") {
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 20px;
  }
}


.description {
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-fill-1);
  margin-bottom: 28px;
  @include media("tablet-portrait") {
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 18px;
  }
}

.button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
