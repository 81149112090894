@import "styles/variables";
@import "styles/mixins";

.socialItem:not(:last-child) {
  margin-right: $spacing-4;
}

.socialItemLink {
  display: flex;
  color: var(--color-fill-1);
  font-size: pxToRem(20);
}
