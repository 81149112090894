@import "styles/variables";
@import "styles/mixins";

$icon-size: 22px;

.select {
  position: relative;
  cursor: pointer;

  &::placeholder {
    color: var(--color-fill-3);
  }

  .arrowIcon {
    position: absolute;
    right: $spacing-2;
    top: 50%;
    width: $icon-size-s;
    height: $icon-size-s;
    transform: translateY(-50%);
    color: var(--color-primary-action-text);
    pointer-events: none;
  }

  :global(.rc-select-selector) {
    width: 100%;
    padding: $spacing-2;
    border: 1px solid var(--color-separator);

    :global(.rc-select-selection-search) {
      position: relative;

      :global(.rc-select-selection-search-mirror) {
        position: absolute;
        z-index: 999;
        white-space: nowrap;
        position: none;
        left: 0;
        top: 0;
        visibility: hidden;
      }

      :global(.rc-select-selection-search-input) {
        display: none;
      }
    }

    :global(.rc-select-selection-item) {
      color: var(--color-fill-1);
      font-size: pxToRem(17);
      line-height: pxToRem(20);
      font-weight: 500;

      .disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    :global(.rc-select-selection-item-content) {
      margin-left: $spacing-1;
    }
  }
}

.dropdown {
  position: absolute;
  background-color: var(--color-white);
  padding: $spacing-2 0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  width: auto;
  z-index: 2;

  &:global(.rc-select-dropdown-hidden) {
    display: none;
  }
}

.option {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: $spacing-2 $spacing-3;
  cursor: pointer;
  color: var(--color-fill-1);

  :global(.rc-select-item-option-content) {
    flex: 1;
  }

  &:not(:global(.rc-select-item-option-selected)) {
    &:after {
      content: " ";
      display: block;
      width: $icon-size;
      height: $icon-size;
      border-radius: $spacing-1;
      border: 1px solid var(--color-separator);
      margin-right: $spacing-2;
    }
  }
}

.checkedIcon {
  color: var(--color-white);
  background: var(--color-primary-action);
  border-radius: $spacing-1;
  font-size: $icon-size;
  display: block;
  margin-right: $spacing-2;
}

.optionTitle {
  display: inline;
}
