@import-normalize;
@import "styles/colors";
@import "styles/theme";
@import "styles/fonts/brandon";
@import "features/ecommerce/styles/snipcart.override.scss";

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

html {
  font-size: 16px;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

body {
  margin: 0;
  padding: 0;
  background: var(--color-default-khaki);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Brandon Grotesque", Calibri, "Gill Sans", "Gill Sans MT",
    "Trebuchet MS", sans-serif;
}

button,
input,
textarea,
select {
  font-family: inherit;
}

// reset styling of button
button {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

select {
  padding: 0;
  border: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border-radius: 0;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  background-image: none;
  background-color: transparent;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

:focus {
  outline: 0;
}

::-moz-focus-inner {
  border: 0;
}
