@import "styles/variables";
@import "styles/mixins";

.navButtons {
  .navButton {
    z-index: 2;
  }

  &.navButtonsDefault {
    position: absolute;
    display: none;
    top: -$spacing-6;
    right: $spacing-3;

    @include media("tablet-portrait") {
      display: block;
    }
    .navButton {
      &:last-of-type {
        margin-left: $spacing-6;
      }

      &.disabled {
        opacity: 0.7;
        pointer-events: none;
      }
    }
  }

  &.navButtonsCenter {
    .navButton {
      position: absolute;
      bottom: 0;

      &:first-child {
        left: 0;
      }
      &:last-child {
        right: 0;
      }

      @include media("tablet-portrait") {
        top: 50%;
        transform: translateY(-50%);
        bottom: initial;
      }
    }

    svg {
      font-size: pxToRem(40);
    }
  }
}

.swiperWrapper {
  position: relative;

  :global(.swiper-container) {
    overflow: visible;
  }

  :global(.swiper-pagination) {
    display: flex;
    height: $spacing-1 / 2;
    background-color: var(--color-separator);
    position: absolute;
    width: calc(100% - #{$spacing-6});
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;

    span {
      display: flex;
      width: 100%;
      &:global(.swiper-pagination-bullet-active) {
        background-color: var(--color-primary-action-text);
      }
    }
  }
}
