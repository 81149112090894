@import "styles/variables";
@import "styles/mixins";

.wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @include media("tablet-landscape") {
    flex-direction: row;
  }
}

.reverse {
  @include media("tablet-landscape") {
    flex-direction: row-reverse;
  }
}

.fullWidth {
  @include media("tablet-landscape") {
    flex-direction: column;
  }
}

.media {
  width: 100%;
  @include media("tablet-landscape") {
    width: 45%;
  }
}

.description {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  @include media("tablet-landscape") {
    margin: 20px 0;
    width: 50%;
  }
}

.wide {
  @include media("tablet-landscape") {
    width: 100%;
    margin-bottom: 40px;
  }
}

.title {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 6px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.subtitle {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #d9b677;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.subtitle_description {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: 2px;
  color: #fff;
}