@import "styles/variables";
@import "styles/mixins";

.content {
  overflow-y: scroll;
}

.userContainer {
  padding: $spacing-6 $spacing-3 $spacing-6 $spacing-6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user {
  display: flex;
  align-items: center;
}

.avatar {
  margin-right: $spacing-2;
}

.userControlButton {
  cursor: pointer;

  font-size: pxToRem(24);

  &:not(:last-child) {
    margin-right: $spacing-2;
  }
}

.buttonDisabled {
  cursor: default;
  pointer-events: none;
}

.controls {
  height: 120px;
  padding: $spacing-3 $spacing-6;
}

.controlsTitle {
  margin-bottom: $spacing-3;
  color: var(--color-fill-3);
}

.actionButton {
  &:not(:last-child) {
    margin-right: $spacing-4;
  }

  &.isOff {
    color: var(--color-on-primary-action);
    background-color: var(--color-semantics-warning);
    border: none;
  }
}

.userControls {
  display: flex;
  align-items: center;
}

.privateChatHeader {
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--color-separator);
  margin-bottom: $spacing-4;
}

.privateChatBackContent {
  justify-content: flex-start;
}

.chatBackButton {
  padding: $spacing-3 $spacing-4 $spacing-3 $spacing-6;
}

.chatBackIcon {
  font-size: $spacing-6;
  color: var(--color-primary-action-text);
}

.dropdown {
  :global(.rc-dropdown-menu) {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  }
}
