@import "styles/variables";

.container {
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--color-fill-3);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-6 $spacing-4 $spacing-4 $spacing-6;
}

.button {
  justify-content: flex-end;
  flex: 1;
}

.option {
  width: 100%;
  padding: $spacing-4 0;
  justify-content: flex-start;
  &:not(:last-child) {
    border-bottom: solid 1px var(--color-background-2);
  }
}

.dropdown {
  :global(.rc-dropdown-menu) {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  }
}

.active {
  color: var(--color-primary-action-text);
}
