@import "styles/variables";
@import "styles/mixins";

.container {
  text-align: center;
  .titleContainer {
    margin-top: $spacing-15;
    margin-bottom: $spacing-15;
  }
  .subTitle {
    margin-top: $spacing-4;
  }
  .experiences {
    margin-bottom: $spacing-10;

    @include media("tablet-landscape") {
      margin-bottom: $spacing-15;
    }
  }
}
