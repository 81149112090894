.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  height: 18px;
  width: 18px;
  margin-right: 16px;
}


.level {
  width: 3pt;
  background-color: #9faaa5;
  display: block;
}

.low {
  height: 9px;
}

.medium {
  height: 13px;

}

.high {
  height: 17px;
}

.filled {
  background-color: #fff;
}
