@import "styles/variables";
@import "styles/mixins";

.toolbar {
  height: $video-toolbar-height-mobile;
  padding: 0 $video-toolbar-spacing-mobile;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;

  @include media("tablet-landscape") {
    height: $video-toolbar-height-desktop;
    padding: 0 $video-toolbar-spacing-desktop;
  }
}

.backButtonIcon {
  @include media("tablet-portrait-max") {
    color: var(--color-primary-action-text);
  }
}
