.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.image {
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.92px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

.subtitle {
  margin-bottom: 65px;
  font-size: 17px;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  max-width: 500px;
}

.button {
  max-width: 400px;
}