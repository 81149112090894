@import "styles/mixins";
@import "styles/variables";

$max-banner-height: 560px;

.imageWrapper {
  padding-bottom: $image-aspect-ratio-3-to-2;

  @include media("tablet-landscape") {
    padding-bottom: $image-aspect-ratio-5-to-2;
  }

  @include media("desktop-medium") {
    padding-bottom: 0;
    height: $max-banner-height;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
}
