.snipcart-discount-box__button {
  @extend %title3;
  @extend %large;
  color: var(--color-primary-action-text);
  border: solid 1px var(--color-primary-action-text);
  position: relative;
  padding: $spacing-2;
  padding-left: $spacing-8;

  &::before {
    content: "";
    width: pxToRem(12);
    height: pxToRem(12);
    position: absolute;
    left: $spacing-2;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../icons/add.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.snipcart-discount-box {
  margin-bottom: $spacing-6;

  .snipcart-discount-box__form-container {
    position: relative;

    .snipcart-discount-box__form {
      .snipcart-input {
        width: calc(100% - 80px);

        @include media("tablet-landscape") {
          width: calc(100% - 100px);
        }
      }

      .snipcart-discount-box__submit {
        position: absolute;
        right: 80px;
        top: 0;
        background: var(--color-primary-action);
        color: var(--color-on-primary-action);
        padding: $spacing-2 $spacing-5;
        @extend %title3;
        @extend %large;

        @include media("tablet-landscape") {
          right: 100px;
        }
      }
    }

    .snipcart-discount-box__cancel {
      position: absolute;
      right: 0;
      top: 10px;
      @extend %title3;
      @extend %large;
      color: var(--color-primary-action-text);
    }
  }
}

.snipcart-cart-summary-fees__discounts-icon {
  display: none;
}
