@import 'styles/colors';
$color1: #fff;
$color2: $color-khaki-light;

.radio {

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      color: $color2;
      &:before {
        content: '';
        background: $color1;
        border-radius: 100%;
        border: 1px solid darken($color1, 25%);
        display: inline-block;
        width: 1.2em;
        height: 1.2em;
        position: relative;
        top: -0.2em;
        margin-right: 0.6em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 200ms ease;

      }
    }
    &:checked {
      + .radio-label {
        color: $color-default-khaki;
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 4px $color1;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $color2;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $color1;
          border-color: darken($color1, 25%);
          background: darken($color1, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }


}