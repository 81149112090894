@import "styles/variables";
@import "styles/mixins";

.spinner {
  padding-top: 30px;
  padding-bottom: 10px;
}

.center {
  align-content: center;
}

.full_width_video {
  width: 100%;
}

.article_width_video {
  width: 100%;
  @include media("tablet-landscape") {
    width: 90%;
  }
  @include media("desktop-medium") {
    width: 70%;
  }
}

.small_video {
  max-width: 720px;
}

.container_outer {
  margin: auto;
}

.container {
  position: relative;
  margin:auto;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  padding-bottom: 25px;
  padding-top: 32px;
  text-align: left;
  font-size: 1.2rem;
  letter-spacing: 0.12rem;
}

.rich_text {
  padding: 0 0;
}