@import "styles/variables";
@import "styles/mixins";

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--color-global-overlay);
  height: 100%;
  z-index: 1050;
}

.modal {
  position: relative;
  width: 100%;
  margin: $spacing-2 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container {
    position: relative;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  @include media("mobile-landscape") {
    width: 342px;
    margin: 0 auto;
  }
}

.header {
  padding: $spacing-4 $spacing-6 0;
  margin-bottom: $spacing-6;

  &.headerCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.title {
  color: var(--color-fill-2);
  margin-top: $spacing-4;
  margin-bottom: $spacing-4;
}

.closeButton {
  position: absolute;
  top: $spacing-4;
  right: $spacing-4;
  font-size: $icon-size-s;
  z-index: 10; // ios requires for it to work
}

.spinner {
  margin-top: 32px;
}