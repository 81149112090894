@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
}

.label {
  font-size: 20px;
  color: $color-primary-action;
  margin-right: 10px;
}

.title {
  color: $color-primary-action;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}