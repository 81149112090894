@import "styles/variables";
@import "styles/mixins";

.tab {
  padding: 0;
  margin-right: $spacing-6;
  text-align: center;
  flex-direction: column;
  height: auto;
  margin-top: $spacing-2;
  margin-bottom: $spacing-5;

  @include media("tablet-landscape") {
    margin-right: $spacing-12;
    margin-bottom: $spacing-7;
  }
}

.icon {
  display: none;
}

.activeTab {
  .icon {
    display: block;
  }
}

.filters {
  margin-bottom: $spacing-11;

  @include media("tablet-landscape") {
    margin-bottom: $spacing-15;
  }
}

.container {
  position: relative;

  .clearContainer {
    text-align: center;
    @include media("tablet-landscape") {
      /* To allow absolute positioning of clear button */
      position: unset !important;
    }
  }

  .clearButton {
    @include media("tablet-landscape") {
      position: absolute;
      right: 0;
      top: $spacing-2;
    }
  }
}
