@import "styles/variables";

.dropdown {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-2;
  border: 1px solid var(--color-separator);
  position: relative;

  .chevronIcon {
    font-size: $icon-size-s;
    color: var(--color-primary-action-text);
    position: absolute;
    right: $spacing-2;
    top: 50%;
    transform: translateY(-50%);
  }
}
