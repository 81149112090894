.image {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.round {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}