@import "styles/variables";
@import "styles/helpers";

.modal {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.item {
  margin-bottom: $spacing-4;
  &.active {
    border: 1px solid var(--color-primary-action-text);
  }
}
