@import "styles/variables";
@import "styles/mixins";

.bottomGridView {
  flex: 1 1 100%;

  @include media("tablet-landscape") {
    margin-bottom: $spacing-1;
  }
}

.fullGridView {
  width: 100%;
  height: 100%;
}

.isHidden {
  display: none;
}

.innerContainer {
  @include media("tablet-landscape") {
    margin: 0 auto;
    max-width: 90vh;
  }

  @include media("desktop-small") {
    max-width: 100vh;
  }
  @include media("desktop-medium") {
    max-width: 120vh;
  }
}
