@import "styles/variables";

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  display: block;
  margin: $spacing-3 0 $spacing-6 0;
  max-width: 500px;
  padding: $spacing-3;
  border: solid 1px var(--color-separator);
  background: white;
  color: var(--color-fill-1);
}

.StripeElement.loading {
  height: $spacing-11;
  opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  padding: 0;
}
