@import "styles/variables";
@import "styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  order: -1;
  margin-bottom: 10px;
  position: relative;
  @include media("tablet-landscape") {
    flex-direction: row;
    align-items: center;
    order: initial;
    margin-bottom: 0;
  }
}


.subtitle {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #d9b677;
  text-transform: uppercase;
  margin-right: 10px;
  margin-bottom: 10px;
  @include media("tablet-landscape") {
    margin-bottom: 0;
  }
}

.icons {
  display: flex;
  position: relative;
  cursor: pointer;
}


.icon {
  width: 40px;
  height: 40px;
  border: solid 1px rgba(255, 255, 255, 0.35);
  object-fit: cover;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  @include media("tablet-landscape") {
    margin-right: 0;
  }

}

.item-0 {
  z-index: 10;
}

.item-1 {
  @include media("tablet-landscape") {
    z-index: 9;
    transform: translateX(-15px);
  }
}

.item-2 {
  @include media("tablet-landscape") {
    z-index: 8;
    transform: translateX(-25px);
  }
}

.item-3 {
  @include media("tablet-landscape") {
    z-index: 7;
    transform: translateX(-35px);
  }
}

.item-4 {
  @include media("tablet-landscape") {
    z-index: 6;
    transform: translateX(-45px);
  }
}

.item-5 {
  @include media("tablet-landscape") {
    z-index: 5;
    transform: translateX(-55px);
  }
}

.item-6 {
  @include media("tablet-landscape") {
    z-index: 4;
    transform: translateX(-65px);
  }
}

.item-7 {
  @include media("tablet-landscape") {
    z-index: 3;
    transform: translateX(-75px);
  }
}

.item-8 {
  @include media("tablet-landscape") {
    z-index: 2;
    transform: translateX(-85px);
  }
}

.item-9 {
  @include media("tablet-landscape") {
    z-index: 1;
    transform: translateX(-95px);
  }
}

.dropdown {
  display: none;
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #6d7f75;
  padding: 10px;
  top: 100%;
  z-index: 6;
  transition: all 0.5s linear;
}

.dropdownLeft {
  left: 100px;
}

.dropdownRight {
  right: 0;
}

.dropdownVisible {
  @include media("tablet-landscape") {
    display: block;
  }
}

.menuList {
  margin: 0;
  padding: 0;
}

.link {
  color: #fff;
  text-decoration: none;
}

.listItem {
  list-style: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}

.listItem:not(:last-child) {
  margin-bottom: 5px;
}

.listItemText {
  margin-left: 10px;
  font-size: 17px;
  font-weight: 500;
}