.snipcart-order__details {
  padding: $spacing-5;

  @include media("tablet-landscape") {
    padding: $spacing-10 $spacing-15;
  }

  .snipcart-order__box__header {
    text-align: center;
  }
  .snipcart-order__invoice-number {
    @extend %subtitle;
    margin-top: $spacing-2;
  }

  .snipcart-order__details__content {
    .snipcart-order__box {
      padding: $spacing-4 0;
      border-bottom: 1px solid var(--color-separator);
      margin-bottom: $spacing-4;
    }
  }

  .snipcart-order__details-title,
  .snipcart-order__title {
    display: flex;
    align-items: center;
    h1 {
      font-size: pxToRem(24);
      margin-left: $spacing-4;
    }
  }

  .snipcart-billing-completed__header {
    h1 {
      font-size: pxToRem(24);
      display: flex;
      align-items: center;
      svg {
        margin-right: $spacing-4;
      }
    }
  }

  .snipcart-order__step-title {
    @extend %subtitle;
    margin: $spacing-4 0;
  }

  .snipcart-order__card {
    display: flex;
    align-items: center;
    @extend %subtitle;

    .snipcart-card-icon {
      svg {
        width: pxToRem(32);
        height: pxToRem(32);
        margin-right: $spacing-4;
      }
    }
  }
}
