@import "styles/variables";
@import "styles/mixins";

.bottomLinks {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;

  @include media("tablet-landscape") {
    width: initial;
  }
}
