@import "styles/variables";

.content {
  margin-top: $spacing-12;
  text-align: center;

  .title {
    margin-bottom: $spacing-6;
  }
}
