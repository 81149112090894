@import 'styles/colors';
@import 'styles/mixins';

.rowName {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  margin-bottom: 20px;
}

.rowNumber {
  display: flex;
  flex-wrap: wrap;
}

.addressTitle {
  font-size: 17px;
  color: $color-default-khaki;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 14px;
  font-weight: bold;
}

.prevAddress {
  margin-bottom: 20px;
  padding-left: 28px;
  color: $color-khaki-light;
}

.radio {
  margin-bottom: 20px;
}

.controls {
  margin-top: auto;
  //display: flex;
  //justify-content: flex-end;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.item {
  margin-bottom: 20px;
}

.modalText {
  padding: 0 20px 48px 20px;
  margin-top: -30px;
  font-size: 17px;
  text-align: center;
  line-height: normal;
  color: $color-default-khaki;
}
