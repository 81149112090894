@import "styles/variables";

.cartButton {
  margin-right: $spacing-5;
  color: var(--color-primary-action-text);
  width: $spacing-6;
  height: $spacing-6;

  .counter {
    position: absolute;
    top: $spacing-1;
    left: 0;
    right: 0;
    margin: auto;
    letter-spacing: 0;
  }
}

.searchButton {
  margin-right: $spacing-5;
  color: var(--color-primary-action-text);
  width: $spacing-6;
  height: $spacing-6;
}
