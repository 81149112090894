.imagery-theme {
  --color-fill-1: #ffffff;
  --color-fill-2: #ffffff;
  --color-fill-3: rgba(255, 255, 255, 0.75);
  --color-separator: rgba(255, 255, 255, 0.3);
  --color-background-1: transparent;
  --color-background-2: transparent;
  --color-primary-action-text: #ffffff;
  --color-global-gold: #ffffff;
  --color-link: #d9b677;
  --color-link-hover: #b4965b;
}
