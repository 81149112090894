@import "styles/variables";
@import "styles/mixins";

.container {
  text-align: center;

  @include media("tablet-landscape") {
    text-align: left;
  }
}

.text {
  margin-bottom: $spacing-2;
}
