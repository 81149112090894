@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.wrapperGreen {
  background-color: $color-default-khaki;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 30px;
  justify-content: center;


  @include media("tablet-portrait") {
    height: 100vh;
    flex-direction: row;
    padding: 50px 70px;

  }
}

.left {
  width: 100%;
  @include media("tablet-portrait") {
    width: 40%;
  }
}

.right {
  background-color: #fff;
  padding: 32px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @include media("tablet-portrait") {
    max-width: 600px;
  }
}

.error {
  width: 120px;
  height: 120px;
  margin: 110px auto 44px auto;
}

.title {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.92px;
  text-align: center;
  color: $color-default-khaki;
  text-transform: uppercase;
}

.subtitle {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: $color-default-khaki;
  margin-bottom: 80px;
}

.contact {
  margin-top: auto;
  text-align: center;

  color: $color-khaki-light;
  font-size: 15px;
}

.link {
  color: $color-primary-action;
}