@import "styles/variables";
@import "styles/mixins";

$product-image-height-mobile: 225px;
$product-image-height-desktop: 592px;

.imageWrapper {
  height: $product-image-height-mobile;

  @include media("tablet-landscape") {
    height: $product-image-height-desktop;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.contentWrapper {
  padding: $spacing-7 0;

  @include media("tablet-landscape") {
    padding: $spacing-15 0;
  }
}
