@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.wrapper {
  background-image: url("../../../../assets/images/clouds.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.row {
  margin-bottom: $spacing-6;
}

.header {
  font-size: 22px;
  letter-spacing: 2px;
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  align-items: center;
  margin-bottom: $spacing-7;
}

.paddingTop {
  padding-top: 80px;
  @include media("mobile-landscape") {
    padding-top: 0;
  }
}

.back {
  color: $color-primary-action;
  cursor: pointer;
  height: $spacing-6;
  width: $spacing-6;
  display: none;
  @include media("mobile-landscape") {
    display: flex;
  }
}

.first {
  margin-bottom: $spacing-6;
  @include media("tablet-portrait") {
    padding-right: 5px;
    margin-bottom: 0;
  }
}

.second {
  @include media("tablet-portrait") {
    padding-left: 5px;
  }
}

.label {
  margin-left: 10px;
  width: 16px;
  height: 16px;
  fill: $color-light-gray;
  cursor: pointer;
}
