@import "styles/variables";
@import "styles/mixins";

.wrapper {
}

.subtitle {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #d9b677;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.description {
  font-size: 17px;
  line-height: 1.65;
  letter-spacing: 1px;
  margin-bottom: 28px;
}

.interaction {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 20px;
  @include media("tablet-landscape") {
    flex-direction: row;
    justify-content: space-between;
  }
}

.interactionIcons {
  display: flex;
  flex-direction: column;
  @include media("tablet-landscape") {
    flex-direction: row;
    justify-content: space-between;
  }
}

.icons {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-right: 30px;
}

.icons:not(:last-child) {
  margin-bottom: 20px;
  @include media("tablet-landscape") {
    margin-bottom: 0;
  }
}

.icon {
  margin-right: 16px;
  font-size: 20px;
}
