.snipcart-loading-overlay__content {
  margin-top: $spacing-4;

  .snipcart-payment-card-form {
    iframe {
      width: 100%;
      border: none;
      height: 100px;
    }
  }

  .snipcart-form__footer {
    .snipcart-cart-button {
      width: 100%;
    }
  }

  .snipcart-payment-methods-list {
    list-style: none;
    padding: 0;
    .snipcart-payment-methods-list-item {
      .snipcart-payment-methods-list-item__button {
        background: var(--color-background-1);
        color: var(--color-fill-1);
        box-shadow: inset 0 0 0 1px var(--color-fill-3);
        @extend %title3;
        @extend %large;
        width: 100%;
        padding: $spacing-2 $spacing-5;

        .snipcart-payment-methods-list-item__arrow {
          display: none;
        }

        .snipcart-payment-methods-list-item__label {
          display: flex;
          align-items: center;

          .snipcart-payment-methods-list-item__icon {
            width: 70px;
            height: 26px;
          }
        }
      }
    }
  }
}

.snipcart-payment__no-payment {
  @extend %subtitle;
  margin-top: $spacing-4;
}

.snipcart-payment {
  .snipcart-cart-button {
    width: 100%;
  }
}
