@import 'styles/colors';
.title {
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  color:$color-default-khaki;
  margin-bottom: 16px;
}

.payment {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.link{
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  color:$color-primary-action;
  text-decoration: none;
  margin-left: 16px;
  margin-bottom: 40px;
}
