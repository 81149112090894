%subtitle {
  font-weight: 500;
  font-size: pxToRem(17);
  line-height: pxToRem(20);
  color: var(--color-fill-1);
}

%title1 {
  font-size: pxToRem(24);
  line-height: pxToRem(32);

  @include media("tablet-landscape") {
    font-size: pxToRem(30);
    line-height: pxToRem(40);
  }
}

%title3 {
  color: var(--color-fill-1);
  font-weight: 700;
  font-size: pxToRem(13);
  line-height: pxToRem(24);
  font-weight: 700;
  text-align: right;

  @include media("tablet-landscape") {
    font-size: pxToRem(14);
  }
}

%large {
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.snipcart-modal__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  background: var(--color-global-overlay);

  svg {
    width: pxToRem(24);
    height: pxToRem(24);
  }

  &.snipcart-layout--large {
    .snipcart-layout {
      background: var(--color-background-2);

      .snipcart-layout__content {
        @include media("tablet-landscape") {
          padding: $spacing-10;
        }
      }
    }

    .snipcart-checkout__header {
      border: none;
      padding: $spacing-5;
      .snipcart-cart-header__close-button {
        display: flex;
        justify-content: flex-start;

        @include media("tablet-portrait") {
          width: 100%;
          top: $spacing-5;
          left: $spacing-6;
        }

        svg {
          background-image: url("../icons/back.svg");
        }

        .snipcart-modal__close-title {
          display: initial;
          color: var(--color-primary-action-text);
        }
      }

      & + div {
        display: flex;
        flex-shrink: 1;
        height: calc(100% - #{$spacing-16});
        @include media("tablet-portrait") {
          height: 100%;
        }
      }
    }
  }
}

.snipcart-layout {
  background: var(--color-background-1);
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  @include media("tablet-landscape") {
    width: 75%;
  }

  @include media("desktop-small") {
    width: 50%;
  }
}

.snipcart-cart-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: $spacing-5 $spacing-8;
  border-bottom: 1px solid var(--color-separator);

  @include media("tablet-portrait") {
    padding: $spacing-5 $spacing-12;
  }

  @include media("tablet-landscape") {
    padding: $spacing-5 $spacing-15;
    justify-content: space-between;
  }

  .snipcart-cart-header__count {
    @extend %subtitle;
    @extend %large;
  }
  .snipcart-cart-header__options {
    display: none;
  }

  .snipcart-cart-header__count {
    color: var(--color-primary-action-text);
    @extend %title3;

    svg {
      margin-right: $spacing-1;
      background-image: url("../icons/bag.svg");
      background-repeat: no-repeat;
      background-position: center;

      path {
        display: none;
      }
    }
  }

  .snipcart-modal__close-icon {
    path {
      fill: var(--color-fill-1);
    }
  }
}

.snipcart-cart-header__title {
  @extend %title3;
  @extend %large;
}

.snipcart-layout__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  overflow: auto;
}

.snipcart-cart__footer-col {
  footer {
    display: flex;
    justify-content: flex-end;
  }

  .snipcart-featured-payment-methods__title {
    text-align: center;
    margin-top: $spacing-6;

    a {
      color: var(--color-fill-2);
      font-size: pxToRem(15);
      font-weight: 500;

      svg {
        width: pxToRem(12);
        height: pxToRem(12);
        margin-right: $spacing-2;
        background-image: url("../icons/lock.svg");
        background-repeat: no-repeat;
        background-position: center;

        path {
          display: none;
        }
      }
    }
  }
}

.snipcart-cart-header__close-button {
  @extend %title3;
  @extend %large;

  @include media("tablet-landscape") {
    position: relative;
    top: initial;
    left: initial;
  }

  svg {
    margin-right: $spacing-4;
  }

  & + div {
    @include media("tablet-portrait") {
      width: 50%;
    }
  }
}

.snipcart-cart-summary__separator,
.snipcart-form__separator {
  display: none;
}

.snipcart-layout--large {
  .snipcart-layout {
    width: 100%;
  }
  .snipcart-cart-header {
    display: block;
    @include media("tablet-landscape") {
      display: flex;
    }
  }
}

.snipcart__icon--spin {
  animation: spin 0.7s linear 0s infinite;
  margin: 0 $spacing-4;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}

.snipcart-loading-overlay__pending {
  @extend %subtitle;
  .snipcart__icon {
    animation: spin 0.7s linear 0s infinite;
  }
}

.snipcart-modal__header-summary {
  width: 100%;

  .snipcart-cart-summary--small {
    padding: 0 $spacing-5;
  }

  .snipcart-modal__header-summary-title {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg:first-of-type {
      display: none;
    }

    span {
      @extend %title3;
      color: var(--color-fill-2);
    }
  }
}

.snipcart__loading__screen {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: $spacing-10;

  .snipcart__loading__screen__content {
    display: flex;
    align-items: center;

    svg {
      margin-right: $spacing-5;
      animation: spin 0.7s linear 0s infinite;
    }

    span {
      @extend %subtitle;
    }
  }
}
