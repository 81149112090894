@import "styles/colors";
@import "styles/mixins";

.paragraph {
  font-size: 18px;
  text-align: center;
  color: $color-default-khaki;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: #54685d;
}
