@import "styles/variables";
@import "styles/mixins";

.bottomGridView {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}

.fullGridView {
  width: 100%;

  @include media("tablet-landscape") {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: start;
    grid-gap: 2px;
    max-width: 80vh;
  }

  @include media("desktop-small") {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 130vh;
  }
}

.loading {
  position: relative;
  padding-top: $image-aspect-ratio-5-to-3;
  > * {
    position: absolute;
    top: 50%;
  }
}
