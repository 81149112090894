@import "styles/variables";
@import "styles/mixins";


$colorPinkBase: var(--color-pink-base);
$colorPinkYin: var(--color-pink-yin);
$colorOrangeBase: var(--color-orange-base);
$colorOrangeYang: var(--color-orange-yang);
$colorGreenYang: var(--color-green-yang);
$colorGreenYin: var(--color-green-yin);
$colorDarkText: var(--color-dark-text);
$colorYellowBase: var(--color-yellow-base);
$colorYellowYin: var(--color-yellow-yin);
$colorBlueYin: var(--color-blue-yin);
$colorBlueYang: var(--color-blue-yang);
$colorBlueBase: var(--color-blue-base);
$colorVioletYang: var(--color-violet-yang);
$colorRedYin: var(--color-red-yin);

.wrapper {
  position: relative;
}

.tag {
  padding: $spacing-3/2 $spacing-3;
  color: var(--color-on-primary-action);
  display: inline-block;
  position: absolute;
  background-color: #9f1d1d;
  border-radius: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;


  //&::after {
  //  content: "";
  //  width: 100%;
  //  height: 100%;
  //  border-radius: $spacing-8;
  //  display: inline-block;
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  z-index: 0;
  //  opacity: 0.5;
  //}

  &.pink {
    background-color: $colorPinkBase;
  }

  &.pinkYin {
    background-color: $colorPinkYin;
  }

  &.orange {
    background-color: $colorOrangeBase;
  }

  &.orangeYang {
    background-color: $colorOrangeYang;
  }

  &.green {
    background-color: $colorGreenYang;
  }

  &.greenYin {
    background-color: $colorGreenYin;
  }

  &.darkGreen {
    background-color: $colorDarkText;
  }

  &.yellow {
    background-color: $colorYellowBase;
  }

  &.yellowYin {
    background-color: $colorYellowYin;
  }

  &.blue {
    background-color: $colorBlueYin;
  }

  &.blueYang {
    background-color: $colorBlueYang;
  }

  &.blueYin {
    background-color: $colorBlueBase;
  }

  &.violet {
    background-color: $colorVioletYang;
  }

  &.red {
    background-color: $colorRedYin;
  }
}


.tagLabel {
  position: relative;
  z-index: 1;
}

.linkWrapper {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 6px 12px;
}

.link{
  text-decoration: none;
}