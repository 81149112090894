@import "styles/variables";
@import "styles/colors";

.card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  user-select: none;
}

.card:last-child {
  margin-bottom: 0;
}

.image {
  //flex: 1;
  padding-right: 10px;
  padding-top: 3px;
}

.info {
  padding-left: 15px;
  color: $color-default-khaki;
  flex: 3
}

.title {
  font-size: 22px;
  font-weight: bolder;
  margin-bottom: 6px;
  letter-spacing: 1px;
}

.arrow {
  font-size: 20px;
  cursor: pointer;
}

.author {
  font-size: 17px;
  margin-bottom: 6px;
  letter-spacing: 1px;
}
.duration{
  font-size: 17px;
  letter-spacing: 1px;
}

.quantity {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: $color-default-khaki
}

.number {
  font-size: 20px;
  min-width: 30px;
  text-align: center;
  font-weight: bolder;
}


.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}


.price {
  color: $color-default-khaki;
  margin-bottom: 39px;
}

.remove {
  color: $color-khaki-light;
  cursor: pointer;
}