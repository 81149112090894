@import "styles/colors";

.subtitle {
  font-size: 17px;
  line-height: 1.18;
  text-align: center;
  color: $color-default-khaki;
  margin-top: 120px;
  margin-bottom: 72px;
}

.row {
  margin-bottom: 24px;
}


.choose {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: $color-default-khaki;
  margin-top: 90px;
  margin-bottom: 64px;
}

.label {
  margin-left: 10px;
  width: 16px;
  height: 16px;
  fill: $color-light-gray;
  cursor: pointer;
}