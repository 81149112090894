@import "styles/variables";
@import "styles/mixins";

$overlay-content-spacing: $spacing-3;

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.avatar {
  position: absolute;
  top: calc(50% - #{$spacing-3});
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-items: center;
  height: 64px;

  &.gradient {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
  }
}

.name {
  position: absolute;
  color: var(--color-fill-1);
  bottom: $overlay-content-spacing;

  .showLargeOverlay & {
    top: auto;
    bottom: auto;
  }
}

.toggleMute {
  color: var(--color-fill-1);
  position: absolute;
  right: $overlay-content-spacing;
  bottom: $overlay-content-spacing;
  font-size: pxToRem(24);
}

.notClickableButton {
  cursor: default;
  pointer-events: none;
}
