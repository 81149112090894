.backToTop {
  z-index: 69;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.backToTopIcon {
  color: #ffffff !important;
  background-color: #54685c !important;
  &:hover {
    background-color: #65806d !important;
  }
}
