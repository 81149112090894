@import "styles/variables";
@import "styles/mixins";

.header {
  background: var(--color-background-1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  border-bottom: thin solid var(--color-separator);
  transition: 300ms background ease-in-out;

  &.sticky {
    position: sticky;
  }
}

.headerWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: $spacing-4 $spacing-5;

  @include media("tablet-landscape") {
    justify-content: center;
  }
}

.logo {
  max-height: ($spacing-9 - $spacing-1);
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;

  @include media("desktop-small") {
    flex: 1;
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;

  @include media("desktop-small") {
    flex: 1;
  }
}

.buttons {
  display: flex;
  align-items: center;
  height: $spacing-8;
}
