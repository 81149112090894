@import "styles/variables";
@import "styles/mixins";

.iconAttendees {
  font-size: pxToRem(24);
  margin-right: $spacing-2;
}
.iconChat {
  font-size: pxToRem(24);
  &.disabled {
    color: var(--color-fill-3);
  }
}
