@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

.wrapperGreen {
  background-color: $color-default-khaki;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 10px;
  height: 100%;

  @include media("tablet-portrait") {
    background-image: url("/kaylo-logo.png");
    background-repeat: no-repeat;
    background-position: bottom left 80px;
    background-size: 30%;
    padding: 20px 30px;
  }

  @include media("tablet-portrait") {
    flex-direction: row;
    padding: 50px 70px;

  }
}

.left {
  width: 100%;
  padding-bottom: 70px;
  @include media("tablet-portrait") {
    width: 50%;
  }
}

.logo {
  max-width: 156px;
  margin-left: auto;
  margin-right: auto;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 0;
  position: absolute;
}

.right {
  z-index: 1;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px 14px;

  @include media("tablet-portrait") {
    padding: 32px 30px;
    width: 600px;
    overflow-y: auto;
  }
}

.title {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 2px;
  color: $color-default-khaki;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 17px;
  text-align: center;
  color: $color-default-khaki;
  margin-bottom: 6px;
}

.list {
  margin-bottom: 24px;
  max-height: 600px;
  background-color: rgba(84, 104, 93, 0.05);
  padding: 24px 20px;
  overflow-y: auto;

}

.empty {
  color: $color-default-khaki;
  text-align: center;
}

.confirmation {
  font-size: 15px;
  text-align: center;
  color: $color-khaki-light;
  margin-bottom: 24px;
}

.cancel {
  margin-top: auto;
  font-size: 15px;
  text-align: center;
  color: $color-khaki-light;
  margin-bottom: 24px;
  line-height: 1.4;
}

.questions {
  font-size: 15px;
  text-align: center;
  color: $color-khaki-light;
  line-height: 1.4;
}

.calendar {
  display: block;
  border: solid 1px $color-light-gray;
  padding: 9px 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  color: $color-light-gray;
  text-transform: uppercase;
  margin-bottom: 20px;
  width: 100%;
}

.button {
  margin-bottom: 20px;
}

.link {
  text-decoration: underline;
  color: $color-primary-action
}

.colored {
  color: $color-primary-action
}