@import "styles/variables";

.cardWrapper {
  margin-top: $spacing-8;
  flex: 1;
  display: flex;
  flex-direction: column;

  .cardsFormTop {
    flex: 1;
  }
}

.card {
  width: 100%;
  display: flex;
  align-items: flex-end;

  .label {
    margin-right: $spacing-2;
  }

  .expiry {
    margin-left: $spacing-4;
    margin-right: $spacing-2;
  }
}

.addNewButton {
  margin-bottom: $spacing-6;
}

.checkbox {
  margin-bottom: $spacing-6;
}

.error {
  margin-bottom: $spacing-6;
  color: var(--color-semantics-warning);
}
