@import "styles/variables";

.feedbackModal {
  padding: 0 !important;
}

.wrapper {
  padding: $spacing-5;
  text-align: center;

  .ratingContainer {
    padding-bottom: $spacing-9;
  }

  .rating {
    margin-bottom: $spacing-2;
  }
}
