@import "styles/variables";
@import "styles/mixins";

$background-size-mobile: 500px;
$background-size-desktop: 560px;

.container {
  text-align: left;
  padding-top: $spacing-10;
  padding-bottom: $spacing-10;

  @include media("tablet-landscape") {
    text-align: center;
    padding-top: $spacing-20;
    padding-bottom: $spacing-20;
  }

  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.35;
    background-image: url("../../../assets/images/geometry.svg");
    background-repeat: no-repeat;
    background-position: center -130px;
    background-size: $background-size-mobile;

    @include media("tablet-landscape") {
      background-position: center -140px;
      background-size: $background-size-desktop;
    }
  }
}

.title {
  margin-bottom: $spacing-6;
}

.subTitle {
  margin-bottom: $spacing-4;
}

.link {
  margin-top: $spacing-4;
  color: var(--color-primary-action-text);
  text-decoration: underline;

  h6 {
    text-transform: capitalize;
  }
}
