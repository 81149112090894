@import "styles/variables";

.wrapper {
  display: flex;
  padding: $spacing-5;
  background-color: var(--color-background-2);

  .text {
    flex: 1;
    margin-left: $spacing-4;
  }

  .title {
    margin-top: $spacing-1;
    color: var(--color-fill-2);
  }
}
