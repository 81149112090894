@import "styles/variables";
@import "styles/mixins";

.container {
  margin-bottom: $spacing-10;
}

.session {
  @include media("tablet-landscape") {
    margin-bottom: $spacing-10;
  }
}
