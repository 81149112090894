@import "styles/colors";

.card {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
}

.title {
  color: $color-default-khaki;
}

.name {
  font-size: 22px;
  margin-bottom: 8px;
  text-decoration: underline;
  cursor: pointer;
}

.date {
  font-size: 17px;
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.price {
  font-size: 22px;
  font-weight: 500;
  color: $color-default-khaki;
}

.icon {
  color: gray;
  cursor: pointer;
}