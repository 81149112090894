@import "styles/variables";
@import "styles/mixins";

$tablet-width: 485px;
$desktop-width: 600px;

.modal {
  width: 95%;
  max-width: $tablet-width;

  .content {
    padding: $spacing-5;
  }

  @include media("tablet-landscape") {
    width: $tablet-width;
  }

  @include media("desktop-small") {
    width: $desktop-width;
  }
}

.cancelButton {
  margin-bottom: $spacing-4;

  @include media("tablet-landscape") {
    margin-bottom: 0;
  }
}

.title {
  margin-bottom: $spacing-6;
}

.sessionInfo {
  margin: $spacing-7 0;
  display: flex;
  align-items: center;

  .image {
    width: 60px;
    height: 60px;
    margin-right: $spacing-4;
  }

  .date {
    margin-top: $spacing-2;
  }
}
