@import "styles/variables";
@import "styles/mixins";

.selectWrapper {
  width: 100%;
}

.select {
  height: $input-height;
  width: 100%;
  font-size: pxToRem(17);
  line-height: pxToRem(20);
  font-weight: 500;
  padding: 0 10px;
  border: 1px solid var(--color-separator);
  color: var(--color-fill-1);
  background-color: var(--color-background-1);
  background-image: url("../../../assets/images/chevronDown.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - #{$spacing-4}) center;

  &:focus {
    border-color: var(--color-primary-action-text);
  }
  &.placeholder {
    color: var(--color-fill-3);
  }
}
