@import "styles/colors";

.wrapper {
  position: absolute;
  bottom: -28px;
  font-size: 12px;
  font-weight: 500;
  color: $color-default-khaki;
}

.link {
  text-decoration: underline;
  color: $color-primary-action;
  cursor: pointer;
}