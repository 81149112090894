@import "styles/variables";
@import "styles/mixins";

$quote-wrapper-height-mobile: 260px;
$quote-wrapper-height-desktop: 305px;

.wrapper {
  padding: $spacing-10 0;
  @include media("tablet-landscape") {
    padding: $spacing-15 0;
  }
}

.quoteColumn {
  margin-bottom: $spacing-10;
  @include media("tablet-landscape") {
    margin-bottom: 0;
  }
}

.quoteWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $quote-wrapper-height-mobile;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.7;
    background-image: url("../../../assets/images/geometry.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  @include media("tablet-landscape") {
    min-height: $quote-wrapper-height-desktop;
    margin-bottom: 0;
  }

  .quote {
    text-align: center;
  }
}

.sectionTitle {
  margin-bottom: $spacing-2;
}

.funFacts {
  margin-bottom: $spacing-10;
  span {
    color: var(--color-primary-action-text);
  }
}

.link {
  margin-top: $spacing-2;
  color: var(--color-primary-action-text);
  text-decoration: underline;

  h6 {
    text-transform: capitalize;
    text-decoration: underline;
  }
}
