@import "styles/variables";
@import "styles/mixins";
@import "styles/colors";

$content-wrapper-mobile-outer-spacing: $spacing-6;
$content-wrapper-desktop-outer-spacing: $spacing-7;
$content-wrapper-desktop-gutter: $spacing-3;
.wrapper {
  padding: 0;

  @include media("tablet-landscape") {
    padding: 0 $content-wrapper-desktop-outer-spacing;
  }
  @include media("desktop-small") {
    max-width: $screen-desktop-small-max-width +
      $content-wrapper-desktop-outer-spacing * 2 +
      $content-wrapper-desktop-gutter * 2;
  }
}

.banner {
  height: 500px;
  padding-top: 24px;
  padding-bottom: 56px;
  @include media("tablet-landscape") {
    padding-bottom: 34px;
  }
}

.slider {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28px;
}

.left {
  @include media("tablet-portrait") {
    justify-content: flex-start;
  }
}

.right {
  @include media("tablet-portrait") {
    justify-content: flex-end;
  }
}

.text {
  padding: 20px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  letter-spacing: 1.2px;
  color: #fff;
}

.swipeInfo {
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
  @include media("tablet-landscape") {
    display: block;
  }
}

.swipeInfoMobile {
  padding-top: 10px;
  text-align: center;
  color: #5a675e;
  @include media("tablet-landscape") {
    display: none;
  }
}

.greenLine {
  max-height: 3px;
  background-color: #5a675e;
  @include media("tablet-landscape") {
    display: none;
  }
}