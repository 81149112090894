@import "styles/variables";
@import "styles/mixins";

.itemWrapper {
  position: relative;
  margin: 0 $spacing-3 / 2;
  padding-top: $image-aspect-ratio-3-to-4;

  @include media("tablet-portrait") {
    margin: 0 $spacing-3;
  }

  .image {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .itemContent {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: $spacing-4 $spacing-2;
    text-align: center;

    @include media("tablet-landscape") {
      margin-top: $spacing-9;
    }
  }

  .gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }

  .gradientImg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    opacity: 0;
    transition: 300ms opacity ease-in;
  }

  &.hasLink:hover {
    .gradientImg {
      background: url("../../../../../assets/images/cardBg.png");
      opacity: 0.75;
      background-size: cover;
    }
  }
}

.tagsWrapper {
  display: none;
  height: 42px;
  overflow: hidden;

  @include media("tablet-landscape") {
    display: block;
    margin-top: $spacing-3;
  }

  //.tag {
  //  margin: $spacing-1;
  //}
}

.listPage {
  @include media("tablet-portrait-max") {
    .description {
      display: none;
    }
  }
}
