@import "styles/variables";
@import "styles/mixins";

//Hero height
$hero-desktop-height: 504px;
$hero-mobile-height: 440px;

.heroWrapperHome {
  min-height: $hero-mobile-height;
  display: flex;
  padding-bottom: $section-vertical-spacing-mobile;

  @include media("tablet-landscape") {
    min-height: $hero-desktop-height;
    padding-bottom: $section-vertical-spacing-desktop;
  }

  .blurredBackground {
    position: absolute;
    width: 100%;
    height: calc(100% - #{$section-vertical-spacing-mobile});
    background-size: cover;
    z-index: 0;
    top: $spacing-4;
    opacity: 0.4;
    filter: blur(22px);

    @include media("tablet-landscape") {
      height: calc(100% - #{$section-vertical-spacing-desktop});
    }
  }
}

.heroWrapper {
  min-height: $hero-mobile-height;
  display: flex;
  //padding-bottom: $section-vertical-spacing-mobile;

  @include media("tablet-landscape") {
    min-height: $hero-desktop-height;
    //padding-bottom: $section-vertical-spacing-desktop;
  }

  .blurredBackground {
    position: absolute;
    width: 100%;
    height: calc(100% - #{$section-vertical-spacing-mobile});
    background-size: cover;
    z-index: 0;
    top: -$spacing-12;
    opacity: 0.4;
    filter: blur(22px);

    @include media("tablet-landscape") {
      height: calc(100% - #{$section-vertical-spacing-desktop});
    }
  }
}

.heroContent {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  position: relative;
  top: -$spacing-12;
  .heroSection {
    background: transparent;
    padding-top: $navigation-height-default;

    @include media("tablet-landscape") {
      padding-top: $navigation-height-large;
    }
    @include media("mobile-portrait-max") {
      padding-top: 50px;
      padding-bottom: $navigation-padding-bottom;
    }
  }
}

.heroContentSingle {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  position: relative;
  top: -6vh;
  margin-bottom: -6vh;
  @include media("tablet-landscape") {
    top: -10vh;
    margin-bottom: -10vh;
  }
  .heroSection {
    background: transparent;
    padding-top: $navigation-height-default;

    @include media("tablet-landscape") {
      padding-top: $navigation-height-large;
    }
  }
}

.heroText {
  text-align: center;

  &.textLeft {
    text-align: left;
  }

  &.textRight {
    text-align: right;
  }

  .subtitle {
    color: var(--color-fill-2);
    margin-bottom: $spacing-4;
  }

  .title {
    color: var(--color-fill-1);
    margin-bottom: $spacing-4;
  }

  .description {
    color: var(--color-fill-2);
    margin-bottom: $spacing-9;
  }
}

.illustration {
  display: block;
  margin: auto;
  margin-bottom: $spacing-1;
}
