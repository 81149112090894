@import "styles/variables";
@import "styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  @include media("tablet-landscape") {
    flex-direction: row;
  }
}

.container:not(:last-child) {
  margin-bottom: 20px;
  @include media("tablet-landscape") {
    margin-bottom: 60px;
  }
}


.image {
  width: 100%;
  height: 192px;
  object-fit: cover;
  margin-bottom: 12px;
  @include media("tablet-landscape") {
    width: 288px;
    margin-right: 24px;
  }
}

.content {
}

.header {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
}

.count {
  letter-spacing: 2.8px;
  text-transform: uppercase;
}

.duration {
}

.title {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.description {
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 1.4;
}