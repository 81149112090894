@import "styles/variables";
@import "styles/mixins";

.container {
  display: none;

  @include media("tablet-landscape") {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.text {
  line-height: 1;
}

.icon {
  font-size: pxToRem(24);
  cursor: pointer;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.3;
}
