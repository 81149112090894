@import "styles/variables";
@import "styles/mixins";

$sidebar-header-attendee-spacing: $spacing-6;
$footer-height: 68px;

.container {
  &:not(.chatDisabled) {
    border-bottom: 1px solid var(--color-separator);
  }

  &.chatDisabled {
    overflow-y: scroll;
    // Since position fixed on mobile and no chat window
    margin-bottom: $footer-height;
    height: 100%;

    @include media("tablet-landscape") {
      margin-bottom: 0;
    }
  }

  @include media("tablet-landscape") {
    margin: 0;
  }
}

.header {
  max-height: 250px;
  transition: max-height 0.65s;
  overflow-y: scroll;
  padding: 0 $sidebar-header-attendee-spacing;

  @include media("tablet-portrait-max", "landscape") {
    max-height: 40vh;
  }

  @include media("tablet-landscape") {
    max-height: 75vh;
  }

  .hideHeader & {
    max-height: 0;
  }

  .chatDisabled & {
    max-height: 100%;
  }
}

.headerContent {
  padding: $sidebar-header-attendee-spacing 0;
}

.practitioner {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-4;
}
.practitionerAvatar {
  margin-right: $spacing-2;
}

.time {
  display: flex;
  align-items: center;
  margin-top: $spacing-4;
  margin-bottom: $spacing-2;
}

.date {
  color: var(--color-fill-3);
  margin-bottom: $spacing-4;
}

.button {
  padding: 0 $sidebar-header-attendee-spacing;
  margin: $spacing-3 0;
}

.buttonContent {
  justify-content: flex-start;
}
