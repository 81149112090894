@import "styles/mixins";
@import "styles/variables";

.productExtras {
  margin-top: $spacing-10;

  .additionalInfo {
    margin-top: $spacing-6;
    display: flex;

    .singleInfo {
      display: flex;
      align-items: center;

      &:not(:last-of-type) {
        margin-right: $spacing-10;
      }

      .downloadIcon {
        font-size: pxToRem(24);
        margin-right: $spacing-3;
        color: var(--color-primary-action-text);
      }
    }
  }
}
