@import "styles/variables";

%effect {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  display: block !important;
}

.rc-dropdown {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1070;
  display: block;

  &.rc-dropdown-hidden {
    display: none;
  }

  .rc-dropdown-menu {
    outline: 0;
    position: relative;
    list-style-type: none;
    padding: 0;
    margin: $spacing-2 0 0;
    text-align: left;
    background-color: var(--color-background-2);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    border: none;

    > li {
      margin: 0;
      padding: 0;
    }

    & > .rc-dropdown-menu-item {
      position: relative;
      display: block;
      padding: $spacing-2 $spacing-4;
      clear: both;
      white-space: nowrap;
      cursor: pointer;

      &:first-child {
        padding-top: $spacing-4;
      }

      &:last-child {
        padding-bottom: $spacing-4;
      }
    }
  }

  &.rc-dropdown-slide-up-enter,
  &.rc-dropdown-slide-up-appear {
    @extend %effect;
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }

  &.rc-dropdown-slide-up-leave {
    @extend %effect;
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
  }

  &.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomLeft,
  &.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomLeft,
  &.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomCenter,
  &.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomCenter,
  &.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomRight,
  &.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomRight {
    animation-name: rcDropdownSlideUpIn;
    animation-play-state: running;
  }

  &.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topLeft,
  &.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topLeft,
  &.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topCenter,
  &.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topCenter,
  &.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topRight,
  &.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topRight {
    animation-name: rcDropdownSlideDownIn;
    animation-play-state: running;
  }

  &.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomLeft,
  &.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomCenter,
  &.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomRight {
    animation-name: rcDropdownSlideUpOut;
    animation-play-state: running;
  }

  &.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topLeft,
  &.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topCenter,
  &.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topRight {
    animation-name: rcDropdownSlideDownOut;
    animation-play-state: running;
  }

  @keyframes rcDropdownSlideUpIn {
    0% {
      opacity: 0;
      transform-origin: 0% 0%;
      transform: scaleY(0);
    }
    100% {
      opacity: 1;
      transform-origin: 0% 0%;
      transform: scaleY(1);
    }
  }
  @keyframes rcDropdownSlideUpOut {
    0% {
      opacity: 1;
      transform-origin: 0% 0%;
      transform: scaleY(1);
    }
    100% {
      opacity: 0;
      transform-origin: 0% 0%;
      transform: scaleY(0);
    }
  }

  @keyframes rcDropdownSlideDownIn {
    0% {
      opacity: 0;
      transform-origin: 0% 100%;
      transform: scaleY(0);
    }
    100% {
      opacity: 1;
      transform-origin: 0% 100%;
      transform: scaleY(1);
    }
  }
  @keyframes rcDropdownSlideDownOut {
    0% {
      opacity: 1;
      transform-origin: 0% 100%;
      transform: scaleY(1);
    }
    100% {
      opacity: 0;
      transform-origin: 0% 100%;
      transform: scaleY(0);
    }
  }
}
