@import "styles/colors";

.title {
  color: $color-default-khaki;
  text-transform: uppercase;
  font-size: 32px;
  margin-bottom: 32px;
}

.orderSummary {
  color: $color-default-khaki;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 12px;
}

.cardList {
  width: 100%;
  border-bottom: 1px solid lightgray;
  margin-bottom: 12px;
}

.discount {
  color: $color-default-khaki;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  margin-top: 22px;
  font-size: 22px;
}

.estimatedTotal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: $color-default-khaki;
  font-size: 22px;
  font-weight: 500;
}

.shipping {
  font-size: 16px;
  color: $color-khaki-light;
  margin-bottom: 40px;
}