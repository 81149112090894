@import "styles/variables";
@import "styles/mixins";


$color-background-solid_auth: #2f3c34;
$color-background-solid_no_auth: #2f3c34;
$color-text_no_auth: #fff;
$color-text_auth: #fff;
$color-background-transparent_no_auth: rgba(47, 60, 52, 0.75);
$color-background-transparent_auth: rgba(47, 60, 52, 0.75);
.wrapper {
  background-color: $color-background-solid_auth;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  z-index: 20;
  cursor: pointer;
  @include media("tablet-landscape") {
    width: 56px;
    height: 56px;
    right: auto;
    bottom: 20px;
    left: 0;
    border-radius: initial;
    color: $color-background-solid_auth;
  }

  &.noAuth {
    background-color: $color-background-transparent_no_auth;
  }
}

.image {
  fill: #ffffff;
  height: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 84%;
  }

  svg {
    width: 24px;
    height: 22px;
    margin-left: 3px;
    color: $color-text_no_auth;
    @include media("tablet-landscape") {
      width: 30px;
      height: 28px;
    }

    path {
      fill: $color-text_auth;
    }
  }

  &.noAuth {
    svg {
      path {
        fill: $color-text_no_auth;
      }
    }
  }
}