@import "styles/breakpoints";
@import "styles/variables";
@import "styles/mixins";

$content-wrapper-mobile-outer-spacing: $spacing-6;
$content-wrapper-desktop-outer-spacing: $spacing-7;
$content-wrapper-desktop-gutter: $spacing-3;

.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 $content-wrapper-mobile-outer-spacing;

  @include media("tablet-landscape") {
    padding: 0 $content-wrapper-desktop-outer-spacing;
  }
  @include media("desktop-small") {
    max-width: $screen-desktop-small-max-width +
      $content-wrapper-desktop-outer-spacing * 2 +
      $content-wrapper-desktop-gutter * 2;
  }
}
