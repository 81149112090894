@import "styles/variables";
@import "styles/mixins";

.profile {
  display: flex;
  align-items: center;
  padding: $spacing-1;
  background-color: var(--color-separator);
  border-radius: $spacing-6;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  }
}

.icon {
  margin: 0 $spacing-1;
  font-size: pxToRem(24);
}
