@import "styles/variables";
@import "styles/mixins";

$wrapper-height-mobile: 284px;
$wrapper-height-tablet: 264px;
$wrapper-height-desktop: 330px;

.wrapper {
  height: $wrapper-height-desktop;


  &:before {
    content: "";
    background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.5) 100%
    );
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
  }

  @include media("tablet-landscape") {
    height: $wrapper-height-desktop;
  }

  @include media("desktop-medium") {
    height: $wrapper-height-desktop;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  z-index: 2;
  align-items: center;
}

.title {
  margin-bottom: $spacing-2;

  @include media("tablet-landscape") {
    margin-bottom: $spacing-4;
    margin-top: $spacing-4 * 2;
  }
}

.form {
  margin: $spacing-4 0;

  @include media("tablet-landscape") {
    display: flex;
    align-items: flex-start;

    .inputWrapper {
      width: 328px;
      margin-right: $spacing-6;
    }

    .button {
      width: auto;
      margin-top: 0;
    }
  }
}

.image {
  background-position-y: top;
}

.footerText {
  margin-bottom: $spacing-2;
}

.thankYouText {
  margin: $spacing-15 0;

  @include media("tablet-landscape") {
    margin: $spacing-14 0;
  }
}
