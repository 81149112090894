@import "styles/variables";
@import "styles/mixins";

.swiper {
  padding-top: 0;
  overflow: hidden !important;
  padding-bottom: $spacing-6;

  @include media("tablet-portrait") {
    padding-top: $spacing-6;
    padding-bottom: $spacing-12;
  }
}

.itemWrapper {
  margin: 0 $spacing-3;
}

.imageWrapper {
  position: relative;
  padding-top: $image-aspect-ratio-16-to-9;
}

.title {
  text-align: center;
  margin-bottom: $spacing-4;

  @include media("tablet-portrait") {
    margin-bottom: 0;
  }
}
