.pink-on-orange-theme {
  --color-fill-1: #ffc9d3;
  --color-fill-2: #ffc9d3;
  --color-fill-3: #ffc9d3;
  --color-separator: rgba(255, 201, 211, 0.15);
  --color-background-1: #ff6d2d;
  --color-background-2: #ff6d2d;
  --color-global-gold: linear-gradient(0deg, #ffc9d3 0%, #ffc9d3 100%);
  --color-primary-action: #ffc9d3;
  --color-primary-action-text: #ffc9d3;
  --color-on-primary-action: #ff6d2d;
}

.violet-on-green-theme {
  --color-fill-1: #bb93dd;
  --color-fill-2: #bb93dd;
  --color-fill-3: #bb93dd;
  --color-separator: rgba(187, 147, 221, 0.15);
  --color-background-1: #006548;
  --color-background-2: #006548;
  --color-global-gold: linear-gradient(0deg, #bb93dd 0%, #bb93dd 100%);
  --color-primary-action: #bb93dd;
  --color-primary-action-text: #bb93dd;
  --color-on-primary-action: #006548;
}

.violet-on-yellow-theme {
  --color-fill-1: #807390;
  --color-fill-2: #807390;
  --color-fill-3: #807390;
  --color-separator: rgba(128, 115, 144, 0.15);
  --color-background-1: #ffe000;
  --color-background-2: #ffe000;
  --color-global-gold: linear-gradient(0deg, #807390 0%, #807390 100%);
  --color-primary-action: #807390;
  --color-primary-action-text: #807390;
  --color-on-primary-action: #ffe000;
}

.blue-on-yellow-theme {
  --color-fill-1: #445571;
  --color-fill-2: #445571;
  --color-fill-3: #445571;
  --color-separator: rgba(68, 85, 113, 0.15);
  --color-background-1: #ffc03c;
  --color-background-2: #ffc03c;
  --color-global-gold: linear-gradient(0deg, #445571 0%, #445571 100%);
  --color-primary-action: #445571;
  --color-primary-action-text: #445571;
  --color-on-primary-action: #ffc03c;
}

.red-on-orange-theme {
  --color-fill-1: #ce0e2d;
  --color-fill-2: #ce0e2d;
  --color-fill-3: #ce0e2d;
  --color-separator: rgba(206, 14, 45, 0.15);
  --color-background-1: #ffa891;
  --color-background-2: #ffa891;
  --color-global-gold: linear-gradient(0deg, #ce0e2d 0%, #ce0e2d 100%);
  --color-primary-action: #ce0e2d;
  --color-primary-action-text: #ce0e2d;
  --color-on-primary-action: #ffa891;
}

.green-on-orange-theme {
  --color-fill-1: #719c8b;
  --color-fill-2: #719c8b;
  --color-fill-3: #719c8b;
  --color-separator: rgba(113, 156, 139, 0.15);
  --color-background-1: #ffa891;
  --color-background-2: #ffa891;
  --color-global-gold: linear-gradient(0deg, #719c8b 0%, #719c8b 100%);
  --color-primary-action: #719c8b;
  --color-primary-action-text: #719c8b;
  --color-on-primary-action: #ffa891;
}
