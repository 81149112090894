@import "styles/variables";
@import "styles/mixins";

.section {
  padding: $spacing-10 0;

  &.noResults {
    padding: 0;
  }

  @include media("tablet-landscape") {
    padding: $spacing-15 0;
  }
}
