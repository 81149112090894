@import "styles/variables";
@import "styles/mixins";

.wrapper {
  padding: $spacing-15 0;

  .subtitle {
    margin-bottom: $spacing-4;
  }

  &.noResults {
    display: none;
  }
}

.practitionerSessions {
  margin-bottom: $spacing-16;

  &.noSpacing {
    margin-bottom: 0;
  }
}
