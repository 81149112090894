@import "styles/variables";
@import "styles/mixins";

.footerLinks {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: $spacing-3;
  margin-bottom: $spacing-10;

  @include media("tablet-landscape") {
    margin-bottom: 0;
  }
}
