@import "styles/variables";
@import "styles/mixins";
@import "styles/helpers";
@import "styles/colors";

.submit {
  text-align: center;
  width: 100%;
  display: block;
  color: #fff;
  background-image: linear-gradient(to right, #b29164, #ffda8a);
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px;
  margin-bottom: 24px;
  &:hover {
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
  }

  &.isDisabled {
    pointer-events: none !important;
  }
}
